import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import YoutubeEmbed from "./shared-ui/Video/YoutubeEmbed";
import Accordion from "./shared-ui/Accordion/Accordion";
import { Button } from "react-bootstrap";

function VideoLibrary(props) {
  const videos = [
    {
      title: "DOPTELLER DIARIES",
      subtitle: "Life After Diagnosis",
      videoId: "ymY55nKFDrQ",
      thumbnail: "dopdiaries-life-after-diagnosis.jpg",
      thumbnailAlt: "Play Video: Dopteller Diaries: Life After Diagnosis",
      categories: ["All", "DOPTELLERS"],
      transcript: (
        <>
          <p>
            I’m Tammy and I live in Washington State, and I’m a teacher, and I
            have had ITP for 15 years now.
          </p>
          <p>
            For people who don’t know, ITP stands for chronic immune
            thrombocytopenia, and it’s where your body decides your platelets
            aren’t the right kind of platelet, and it destroys them. And so, it
            attacks your body, and then you can’t clot, which means when you get
            a cut, then it won’t stop bleeding.
          </p>
          <p>
            When your platelets drop like that, you get tired, usually. Most
            people actually talk about fatigue being an issue. You end up
            finding bruises, or some people have bloody noses a lot, or blood
            blisters in their mouth. But when you first get it, it’s pretty
            scary, because you don’t feel good to a certain extent, but on the
            outside, for the most part, you look okay, so people are kind of
            like, “Well, you look okay, I mean, how can you be sick?” And we get
            that a lot, actually.
          </p>
          <p>
            Everybody has been so grateful that I’ve been sharing my story with
            them, because then they understand, and they’ve all been so helpful,
            they’ll ask if I need anything. If my counts drop, they know that I
            can’t go out for my hikes, maybe even can’t even go for a walk.
            There were times I couldn’t even go outside for a walk, they were
            too low, or maybe I wasn’t feeling good, and I’d have to cancel last
            minute, and they’re all like, “That’s great, no big deal.” And we
            just schedule another time, and they always knew it was tentative,
            and it just made me feel better knowing that they understood what
            was happening.
          </p>
          <p>
            Before I was diagnosed with ITP, I used to be able to get out and
            play softball, or go out on the motorcycle, things like that that
            you can do easily, most people can do easily. But with having ITP,
            it made a little more difficult, because falling or getting hit with
            something makes it dangerous.
          </p>
          <p>
            So, I have made some changes, instead of hitting a softball, I hit a
            golf ball, and instead of being on the motorcycle, now that my
            counts have been up and down, I now go out in the Jeep, and it’s a
            little safer place to be, but I still get out, and I get to still go
            out and have fun with friends, and everybody’s been very helpful
            with that too. When I first started learning more about platelet
            counts, and what was safe at certain numbers, I actually made a list
            of things I could do if I’m at this level, or at this level, or at
            this level, because when you hit the lower levels… I didn’t have a
            whole lot in my life that I could do at that point. And so, I found
            things that I could do at that point saying, “Okay…” And changing my
            mindset of saying, “Okay, what can I do at these counts?” Versus
            thinking about what I couldn’t do. I had my list of 30 or below, or
            50 to 30, and it made me think in a different way, so that I didn’t
            have to think all the negative, and turn it more to a positive.
            There’s so much now, with changing my mindset, thinking about what I
            can do, versus what I can’t do.
          </p>
        </>
      ),
    },
    {
      title: "TAMMY‘S TIPS",
      subtitle: "A Doctor’s Guide To Exploring Treatment Options",
      videoId: "0MBBZAyO_To",
      thumbnail: "tammystips-a-doctors-guide.jpg",
      thumbnailAlt: "Play Video: A Doctor's Guide for Exploring ITP Treatment",
      categories: ["All", "Tammys Tips"],
      transcript: (
        <>
          <p>
            Hi, I’m Tammy and I’ve been living with low blood platelet counts in
            chronic immune thrombocytopenia for over 15 years now. I’ve learned
            a thing or two along the way and now I want to share that with you!
          </p>
          <p>
            Doptelet is a prescription medicine used to treat low blood platelet
            counts in adults with chronic immune thrombocytopenia (ITP) when
            other treatments have not worked well enough.
          </p>
          <p>
            Doptelet is not used to make platelet counts normal in adults with
            chronic ITP. It is not known if Doptelet is safe and effective in
            children.
          </p>
          <p>
            Please welcome Dr. Leila Van Doren who’s going to share her best
            pieces of advice when it comes to exploring treatment options.
          </p>
          <p>
            Thank you for having me, Tammy. I’m Dr. Van Doren, and I have some
            advice to share with you today.
          </p>
          <p>
            First, patients should understand that not every medication will
            work for them or that some medications may work for a while and then
            stop working.
          </p>
          <p>
            The best thing you can do is talk to your doctor if you’re
            dissatisfied with treatment, whatever your reason may be…
          </p>
          <p>
            Definitely, I agree, Dr. Van Doren. When I was concerned about my
            platelet counts, my doctor encouraged me to be as honest as possible
            because there are many treatments out there, but if you don’t speak
            up, you might not get the chance to find that right fit.
          </p>
          <p>
            That’s good advice, as a medical doctor, I also feel the need to
            express that every patient has a unique platelet count and,
            therefore, treatment experience.
          </p>
          <p>Keep in mind every treatment has potential side effects.</p>
          <p>
            Voicing your concerns and being open with your doctor can lead to a
            shared decision that both of you feel comfortable with.
          </p>
          <p>My doctor said the same thing.</p>
          <p>We’re here for you.</p>
          <p>
            And someone else is here for you too, our very own specialist,
            Safety Steve.
          </p>
          <p>
            Hi, I’m Steve. Safety Steve. Safety is my whoooole world. My
            favorite accessory? bandages. I don’t even have cuts on these
            fingers. Favorite summer activity? Applying sunscreen. And favorite
            part of this video? It’s right now. Because I’m about to share
            important safety information about Doptelet…
          </p>
          <p>
            Doptelet doesn’t make platelet counts normal and may cause serious
            side effects, including blood clots, which may be an increased risk
            for people with chronic ITP and certain blood-clotting conditions.
          </p>
          <p>
            Thank you, Steve! For my last tip, only you know what kind of
            treatments will fit best into your day-to-day life…and which ones
            might not.
          </p>
          <p>
            Which is why it’s important to have shared decision making between
            you and your healthcare provider.
          </p>
          <p>
            That is some great advice. Seeking out patient resources and
            educating myself also helped me prepare for and feel more confident
            going into discussions with my doctor.
          </p>
          <p>
            Remember to screenshot these tips for reference later. Now stay
            tuned for some important safety information.
          </p>
        </>
      ),
    },
    {
      title: "TAMMY‘S TIPS",
      subtitle: "Important Questions to Ask Your Doctor",
      videoId: "BuIhQABk2-A",
      thumbnail: "tammystips-important-questions-to-ask-your-doctor.jpg",
      thumbnailAlt:
        "Play Video: Important Questions to Ask Your Doctor About ITP Treatment",
      categories: ["All", "Tammys Tips"],
      transcript: (
        <>
          <p>
            Hi, I’m Tammy and I’ve been living with low blood platelet counts in
            chronic immune thrombocytopenia for over 15 years now. I’ve learned
            a thing or two along the way and now I want to share that with you!
          </p>
          <p>
            Doptelet is a prescription medicine used to treat low blood platelet
            counts in adults with chronic immune thrombocytopenia (ITP) when
            other treatments have not worked well enough.
          </p>
          <p>
            Doptelet is not used to make platelet counts normal in adults with
            chronic ITP. It is not known if Doptelet is safe and effective in
            children.
          </p>
          <p>
            As someone living with low blood platelet counts in chronic ITP
            who’s asked her doctor many questions over the years, I want to
            share some helpful questions to ask your doctor about setting
            treatment goals.
          </p>
          <p>
            The first question is: What would be considered a platelet count
            goal for me?
          </p>
          <p>
            Asking my doctor about my platelet count goal helped open up a
            conversation that led me to trying Doptelet. And I was glad to see
            my platelet counts go up.
          </p>
          <p>
            But if I hadn’t talked to my doctor about wanting a treatment that
            could work for me and my needs, she may not have suggested Doptelet.
          </p>
          <p>
            Thank you, Steve. Now where was I? Oh yes, if you’ve watched other
            videos of me, you’ll know I’ve been on many treatments over the
            years, and what made me consider treatment with Doptelet was the
            fact that my platelet counts were frequently fluctuating.
          </p>
          <p>
            I’m glad I was honest with my doctor about my concerns, because that
            led us to finding a treatment that has raised my platelet counts and
            is keeping my count steady!
          </p>
          <p>
            Be sure to screenshot these questions to bring to your next
            appointment! And don’t forget to keep checking out Tammy’s Tips at
            www.TipsFromTammy.com
          </p>
          <p>Now, stay tuned for some important safety information.</p>
        </>
      ),
    },
    {
      title: "TAMMY‘S TIPS",
      subtitle: "Getting the Most Out of Your Doctor’s Appointments",
      videoId: "Pm7emPcFX08",
      thumbnail:
        "tammystips-getting-the-most-out-of-your-doctors-appointments.jpg",
      thumbnailAlt:
        "Play Video: Tips for Getting the Most Out of Your Doctor's Appointments",
      categories: ["All", "Tammys Tips"],
      transcript: (
        <>
          <p>
            Hi, I’m Tammy and I’ve been living with low blood platelet counts in
            chronic immune thrombocytopenia for over 15 years now. I’ve learned
            a thing or two along the way, and now I want to share that with you!
          </p>
          <p>
            Doptelet is a prescription medicine used to treat low blood platelet
            counts in adults with chronic immune thrombocytopenia (ITP) when
            other treatments have not worked well enough.
          </p>
          <p>
            Doptelet is not used to make platelet counts normal in adults with
            chronic ITP. It is not known if Doptelet is safe and effective in
            children.
          </p>
          <p>I may be biased, but this is a good one.</p>
          <p>
            Today, I’m covering my top 4 ideas for people living with low blood
            platelet counts in chronic ITP to get the most out of their doctor’s
            appointments.
          </p>
          <p>
            One: As intimidating as it is to talk or feel like you’re
            complaining to a medical professional they want what’s best for you.
            It takes teamwork to reach your treatment goals, so creating an open
            dialogue is key, and it’s important to be your own advocate.
          </p>
          <p>
            Two: Write down questions before going. For example, what, if any,
            adjustments will I have to make to my day-to-day life when I start
            treatment?
          </p>
          <p>
            Hi, hematologist here! Questions help me understand where your
            concerns lie, so I can better focus my answers and make it a more
            productive experience for both of us.
          </p>
          <p>Yes, what she said.</p>
          <p>Bye!</p>
          <p>
            Three: If you can, try to bring someone else with you because it can
            be hard to remember everything.
          </p>
          <p>
            Four: Don’t let your doctor leave until you have your answers. If
            you’re unsure of anything, like what’s happening with your
            platelets, always make sure to ask your doctor to explain, rather
            than assume you already know the answer.
          </p>
          <p>
            Listen to really understand what your doctor is saying because, in
            the end, you can’t make a shared decision without them, unless
            you’re both informed going into treatment conversations.
          </p>
          <p>
            Feel free to screenshot these tips and keep them handy. And don’t
            forget to watch other Tammy’s Tips videos at www.TipsFromTammy.com
          </p>
        </>
      ),
    },
    {
      title: "DOPTELLER DIARIES",
      subtitle: "Being Me",
      videoId: "_sHszTR2J8c",
      thumbnail: "dopdiaries-being-me.jpg",
      thumbnailAlt: "Play Video: Dopteller Diaries: Living with ITP",
      categories: ["All", "DOPTELLERS"],
      transcript: (
        <>
          <p>
            I'm Tammy and I live in Washington state and I’m a teacher and I
            have had ITP for 15 years now.{" "}
          </p>
          <p>
            I've been on many different treatment options, I've tried just about
            everything. The medications weren't helping me stay up, they would
            be up and then they would drop and then they maybe go up a little
            bit and then they'd go back down and it was this we call it a roller
            coaster because that's what it feels like you're on this roller
            coaster okay you're going up up up and then all of a sudden you're
            down.
          </p>
          <p>
            When I started on Doptelet, my doctor actually called me steady for
            my platelet count which was amazing. It hadn't happened in a long
            time because I'd been going in pretty much weekly blood draws for I
            don't know how many years now and so when she told me I was steady
            then and she said well you can go in every 3 to 4 weeks for a blood
            draw and I started kind of doing the math and 52 blood draws a year
            was what I normally had been doing and now I was down to maybe 12 to
            20 a year and that just was amazing to me because I haven't had that
            in a very long time.
          </p>
          <p>
            Over these last few years I never really thought a lot about the
            future as much because I was always going day to day or week to week
            and now with being on Doptelet I can actually think ahead, my
            husband and I are thinking retirement, you know thinking years ahead
            and it's been really nice to be able to talk about things that are
            years ahead versus just having to focus on days or weeks ahead
            because on other medications that's all I could do it was all about
            okay where am I at and do I need to go back in the hospital for
            another treatment, do I need to start looking at other options and
            it's been so nice not having to think about other options I can just
            be me.
          </p>
        </>
      ),
    },
    {
      title: "TAMMY’S TIPS",
      subtitle: "Things I Wish I Knew When I Was Diagnosed",
      videoId: "zKwNORop9Tw",
      thumbnail: "tammystips-things-i-wish-i-knew.jpg",
      thumbnailAlt: "Play Video: Things to Know After Chronic ITP Diagnosis",
      categories: ["All", "Tammys Tips"],
      transcript: (
        <>
          <p>
            Hi, I’m Tammy, and I’ve been living with low blood platelet counts
            in chronic immune thrombocytopenia for over 15 years now. I’ve
            learned a thing or two along the way, and now I want to share that
            with you!
          </p>
          <p>
            Doptelet is a prescription medicine used to treat low blood platelet
            counts in adults with chronic immune thrombocytopenia (ITP) when
            other treatments have not worked well enough.
          </p>
          <p>
            Doptelet is not used to make platelet counts normal in adults with
            chronic ITP. It is not known if Doptelet is safe and effective in
            children.
          </p>
          <p>
            Let’s talk about the top 3 things I wish I knew when I was diagnosed
            with low platelet counts in chronic immune thrombocytopenia.{" "}
          </p>
          <p>
            First, you have a say in your treatment — if you are not happy with
            how it’s going, don’t be afraid to speak up because what works for
            other people may not work for you. You are your best advocate.{" "}
          </p>
          <p>
            Talking to my doctor helped her understand what wasn’t working for
            me and led us to explore different treatments.{" "}
          </p>
          <p>
            The second thing I wish I knew is that finding a support
            organization may be helpful. One option is the Platelet Disorder
            Support Association.
          </p>
          <p>
            And for my last tip…remember you are not alone; there is a
            supportive community and educational resources out there that you
            can turn to.
          </p>
          <p>
            Hopefully these tips help you find support and the right treatment
            fit for you. Feel free to screenshot them for your reference! Now
            stay tuned for some important safety information.
          </p>
        </>
      ),
    },
  ];
  const categories = ["All", "DOPTELLERS", "Tammys Tips"];
  const [activeVideo, setActiveVideo] = useState(videos[0]);
  const [activeCategory, setActiveCategory] = useState(categories[0]);

  const changeActiveVideo = (index) => {
    setActiveVideo(videos[index]);
    document.querySelector(".video-section").scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const changeActiveCategory = (index) => {
    setActiveCategory(categories[index]);
  };

  return (
    <div className="video-section">
      <Row>
        <Col sm={12} lg={7}>
          <Row className="d-flex d-md-none categories pb-2 mb-2 border-bottom">
            {categories.map((category, i) => {
              return (
                <Button
                  key={category}
                  className={
                    (category == activeCategory ? "active" : "") + " btn-sm"
                  }
                  onClick={(e) => changeActiveCategory(i, e)}>
                  {category}
                </Button>
              );
            })}
          </Row>
          <div className="video-wrapper">
            <YoutubeEmbed
              embedId={activeVideo.videoId}
              image={[activeVideo.thumbnail, activeVideo.thumbnail]}
              imageMobile={[activeVideo.thumbnail, activeVideo.thumbnail]}
              alt={activeVideo.title}
            />
          </div>
          <h4>
            <strong>{activeVideo.title}</strong>
          </h4>
          <p>{activeVideo.subtitle}</p>
          <div className="mobile-transcript d-lg-none">
            <Accordion title="Transcript" markup={activeVideo.transcript} />
          </div>
        </Col>
        <Col sm={12} lg={5}>
          <Row className="d-none d-md-flex categories pb-2 mb-2 border-bottom">
            {categories.map((category, i) => {
              return (
                <Button
                  key={category}
                  className={
                    (category == activeCategory ? "active" : "") + " btn-sm"
                  }
                  onClick={(e) => changeActiveCategory(i, e)}>
                  {category}
                </Button>
              );
            })}
          </Row>
          <Row className="d-flex flex-column video-list pb-2 mb-2 border-bottom">
            <div className="scroll-container">
              {videos.map((video, i) => {
                return video.categories.includes(activeCategory) ? (
                  <Col
                    key={video.videoId}
                    className={
                      (video.videoId == activeVideo.videoId ? "active" : "") +
                      " d-flex p-2"
                    }
                    onClick={(e) => changeActiveVideo(i, e)}>
                    <Col sm={4} className="px-0">
                      <img
                        className="img-responsive"
                        src={"/images/" + video.thumbnail}
                        alt={video.thumbnailAlt}
                      />
                    </Col>
                    <Col sm={8}>
                      <h4>
                        <strong>{video.title}</strong>
                      </h4>
                      <p>{video.subtitle}</p>
                    </Col>
                  </Col>
                ) : (
                  ""
                );
              })}
            </div>
          </Row>
        </Col>
      </Row>
      <Row className="d-none d-lg-block">
        <Col className="transcript-container">
          <Accordion title="Transcript" markup={activeVideo.transcript} />
        </Col>
      </Row>
    </div>
  );
}
export default VideoLibrary;
