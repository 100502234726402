let isValid = false;
let event;

const checkLength = function(object) {
	if (object.value.length > 0) {
		return true;
	} else {
		return false;
	}
}
const validateText = function(input) {
	if (input.type !== "checkbox") {
		if (input.type === 'checkbox') return false; // This was added because "if(input.type !== "checkbox")" is being ignored
		if (!input.required) return false
		let errorMsg = input.parentElement.querySelector('.invalid-feedback');
		if (!errorMsg) {
			errorMsg = input.parentElement.parentElement.querySelector('.invalid-feedback');
		}
		if (input.value.length > 0) {
			isValid = true;
			input.classList.remove('error');
			input.parentElement.classList.remove('error');
			errorMsg.classList.remove('show');
		} else {
			isValid = false;
			input.classList.add('error');
			input.parentElement.classList.add('error');
			errorMsg.classList.add('show');
			event.preventDefault();
		}
	}
}

const validateEmail = function(input) {
	let errorMsg = input.parentElement.querySelector('.invalid-feedback');
	if (!errorMsg) {
		errorMsg = input.parentElement.parentElement.querySelector('.invalid-feedback');
	}
	if (input.type === "email") {
		if (!(checkLength(input))) {
			errorMsg.classList.add('show');
			isValid = false;
			input.classList.add('error');
			input.parentElement.classList.add('error');
			event.preventDefault();
		} else {
			let pattern = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
			if (!pattern.test(input.value)) {
				errorMsg.classList.add('show');
				isValid = false;
				input.classList.add('error');
				input.parentElement.classList.add('error');
				event.preventDefault();
			} else {
				errorMsg.classList.remove('show');
				isValid = true;
				input.classList.remove('error');
				input.parentElement.classList.remove('error');
			}
		}
	}
}

const validateMobile = function(input) {
	let errorMsg = input.parentElement.querySelector('.invalid-feedback');
	if (input.id === "mobile") {
		if (!(checkLength(input))) {
			errorMsg.classList.remove('show');
			isValid = true;
			input.classList.remove('error');
			input.parentElement.classList.remove('error');
		} else {
			let pattern = new RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
			if (!pattern.test(input.value)) {
				errorMsg.classList.add('show');
				isValid = false;
				input.classList.add('error');
				input.parentElement.classList.add('error');
				event.preventDefault();
			} else {
				input.value = input.value.replace(pattern, "($1) $2-$3");
				errorMsg.classList.remove('show');
				isValid = true;
				input.classList.remove('error');
				input.parentElement.classList.remove('error');
			}
		}
	}
}

const validatePhoneRequired = function(input) {
	let errorMsg = input.parentElement.querySelector('.invalid-feedback');
	if (input.id === "phone") {
		let pattern = new RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
		if (!pattern.test(input.value)) {
			errorMsg.classList.add('show');
			isValid = false;
			input.classList.add('error');
			input.parentElement.classList.add('error');
			event.preventDefault();
		} else {
			input.value = input.value.replace(pattern, "($1) $2-$3");
			errorMsg.classList.remove('show');
			isValid = true;
			input.classList.remove('error');
			input.parentElement.classList.remove('error');
		}
	}
}

const validateZip = function(input) {
	let errorMsg = input.parentElement.querySelector('.invalid-feedback');
	if (input.id === "zip") {
		if (!(checkLength(input))) {
			errorMsg.classList.add('show');
			isValid = false;
			input.classList.add('error');
			input.parentElement.classList.add('error');
			event.preventDefault();
		} else {
			let zipRegEx = new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
			if (!zipRegEx.test(input.value)) {
				errorMsg.classList.add('show');
				isValid = false;
				input.classList.add('error');
				input.parentElement.classList.add('error');
				event.preventDefault();
			} else {
				errorMsg.classList.remove('show');
				isValid = true;
				input.classList.remove('error');
				input.parentElement.classList.remove('error');
			}

		}
	}
}

const validateStateDropdown = function(input, formID) {
	const form = document.getElementById(formID);
	if (input.textContent !== undefined) {
		if (input.textContent.includes("Choose")) {
			form.querySelector('.stateDropDown').parentElement.querySelector('.invalid-feedback').classList.add('show');
			isValid = false;
			form.querySelector('.stateDropDown button').classList.add('error');
			form.querySelector('.stateDropDown button').parentElement.parentElement.classList.add('error');
			event.preventDefault();
		}
	} else if (input === "- - Choose - -") {
		form.querySelector('.stateDropDown').parentElement.querySelector('.invalid-feedback').classList.add('show');
		isValid = false;
		form.querySelector('.stateDropDown button').classList.add('error');
		form.querySelector('.stateDropDown button').parentElement.parentElement.classList.add('error');
		event.preventDefault();
	} else {
		form.querySelector('.stateDropDown').parentElement.querySelector('.invalid-feedback').classList.remove('show');
		isValid = true;
		form.querySelector('.stateDropDown button').classList.remove('error');
		form.querySelector('.stateDropDown button').parentElement.parentElement.classList.remove('error');
	}
}

let treatment = false;
const validateDropdown = function(input, formID) {
	const form = document.getElementById(formID);
  if (input == '- - Choose - -') {
    form.querySelector('.descriptionDropdown').parentElement.querySelector('.invalid-feedback').classList.add('show');
    isValid = false;
    form.querySelector('.descriptionDropdown button').classList.add('error');
    form.querySelector('.descriptionDropdown button').parentElement.parentElement.classList.add('error');
    event.preventDefault();
  }
  else {
    form.querySelector('.descriptionDropdown').parentElement.querySelector('.invalid-feedback').classList.remove('show');
    isValid = true;
    form.querySelector('.descriptionDropdown button').classList.remove('error');
    form.querySelector('.descriptionDropdown button').parentElement.parentElement.classList.remove('error');
  }
	if (input == "I'm taking a different treatment for my ITP") {
		treatment = true;
	}
  else {
		treatment = false;
	}
}

const validateTreatment = function(input, formID) {
	const form = document.getElementById(formID);
  if (input == '- - Choose - -') {
    form.querySelector('.itpTreatmentDropdown').parentElement.querySelector('.invalid-feedback').classList.add('show');
    isValid = false;
    form.querySelector('.itpTreatmentDropdown button').classList.add('error');
    form.querySelector('.itpTreatmentDropdown button').parentElement.parentElement.classList.add('error');
    event.preventDefault();
  }
  else {
    form.querySelector('.itpTreatmentDropdown').parentElement.querySelector('.invalid-feedback').classList.remove('show');
    isValid = true;
    form.querySelector('.itpTreatmentDropdown button').classList.remove('error');
    form.querySelector('.itpTreatmentDropdown button').parentElement.parentElement.classList.remove('error');
  }
}

const validateCheckbox = function(input) {
	if (input.type === "checkbox") { //error state for checkbox, not sure if there is one
		if (input.required) {
			let errorMsg = input.parentElement.querySelector('.invalid-feedback');
			if (input.checked) {
				isValid = true;
				errorMsg.classList.remove('show');
				input.classList.remove('error');
				input.parentElement.classList.remove('error');
			} else {
				isValid = false;
				input.classList.add('error');
				input.parentElement.classList.add('error');
				errorMsg.classList.add('show');
				event.preventDefault();
			}
		}
	}
}

const validate = function(e, formID) {
	const form = document.getElementById(formID);
	event = e;
	let inputs = form.querySelectorAll('input');
	inputs.forEach(function(input) {
		validateText(input);
		validateEmail(input);
		validateMobile(input);
		validateZip(input);
		validateCheckbox(input);
	});

	if (form.querySelector('.descriptionDropdown button') !== null) {
		validateDropdown(form.querySelector('.descriptionDropdown button').textContent, formID);
	}
	if (form.querySelector('.itpTreatmentDropdown button') !== null) {
		if (form.querySelector('.itpTreatmentDropdown').parentElement.classList.contains('d-block')) {
			validateTreatment(form.querySelector('.itpTreatmentDropdown button').textContent, formID);
		}
	}

	if (form.querySelector('.stateDropDown') != null) {
		validateStateDropdown(form.querySelector('.stateDropDown'), formID);
	}
	if (!isValid) {
		event.preventDefault();
		return false;
	} else {
		return true;
	}
}

const showAddressTwo = function(event) {
	event.preventDefault();
	event.target.classList.add('d-none');
	document.getElementsByName("address2")[0].parentElement.classList.remove('d-none');
}

export { validateText, validateEmail, validateMobile, validatePhoneRequired, validateZip, validateDropdown, validateTreatment, validateStateDropdown, validateCheckbox, validate, showAddressTwo };
