import React from "react";
import {
	Dropdown,
	DropdownButton,
	Button,
	Form,
	Row,
} from "react-bootstrap";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import {
	validateText,
	validateEmail,
	validateMobile,
	validateZip,
	validateDropdown,
	validateTreatment,
	validateStateDropdown,
	validateCheckbox,
	validate,
	showAddressTwo,
} from "../Validation";
import "../../css/SignUpForm.scss";
import { Redirect } from "react-router-dom";
import axios from "axios";

class SignUpForm extends React.Component {
	constructor(props) {
		const states = [
			"Alabama",
			"Alaska",
			"Arizona",
			"Arkansas",
			"California",
			"Colorado",
			"Connecticut",
			"Delaware",
			"District of Columbia",
			"Florida",
			"Georgia",
			"Hawaii",
			"Idaho",
			"Illinois",
			"Indiana",
			"Iowa",
			"Kansas",
			"Kentucky",
			"Louisiana",
			"Maine",
			"Maryland",
			"Massachusetts",
			"Michigan",
			"Minnesota",
			"Mississippi",
			"Missouri",
			"Montana",
			"Nebraska",
			"Nevada",
			"New Hampshire",
			"New Jersey",
			"New Mexico",
			"New York",
			"North Carolina",
			"North Dakota",
			"Ohio",
			"Oklahoma",
			"Oregon",
			"Pennsylvania",
			"Puerto Rico",
			"Rhode Island",
			"South Carolina",
			"South Dakota",
			"Tennessee",
			"Texas",
			"Utah",
			"Vermont",
			"Virginia",
			"Washington",
			"West Virginia",
			"Wisconsin",
			"Wyoming",
		];
		super(props);
		this.state = {
			dropdownValue: "- - Choose - -",
			stateDropdownValue: "- - Choose - -",
			itpTreatment: "- - Choose - -",
			description: "",
			state: "",
			name: "",
			YourName: "",
			email: "",
			mobile: "",
			address: "",
			address2: "",
			city: "",
			zip: "",
			checked: false,
			errors: {},
			validated: false,
			isValid: false,
			isSubmitting: false,
			termsCheckValue: false,
		};
		this.stateItems = states.map((item) => (
			<Dropdown.Item eventKey={item} key={item}>
				{item}
			</Dropdown.Item>
		));
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
		this.filterStateSelect = this.filterStateSelect.bind(this);
		this.handleStateSelect = this.handleStateSelect.bind(this);
		this.handleTreatment = this.handleTreatment.bind(this);
	}
	componentDidMount() {
		//FORM PROGRESS DATAPUSH
		document.querySelectorAll(".form-group input").forEach((el) => {
			el.addEventListener("blur", function() {
				const form_name =
					this.getAttribute("name") === null ||
						this.getAttribute("name") === undefined
						? document.title
						: this.getAttribute("name");


				if (this.value.length > 0 && !this.classList.contains("completed")) {
					window.dataLayer.push({
						eventCategory: "Form",
						eventAction: "Field Completed",
						eventLabel: `Sign-Up - ${form_name}`,
						event: "e_form_progress",
					});
					this.classList.add("completed");
				} else if (
					!this.classList.contains("completed") &&
					!this.classList.contains("skipped")
				) {
					window.dataLayer.push({
						eventCategory: "Form",
						eventAction: "Field Skipped",
						eventLabel: `Sign-Up - ${form_name}`,
						event: "e_form_progress",
					});

					this.classList.add("skipped");
				}
			});
		});

		//FORM DATALAYER CHECKBOX
		document.querySelectorAll(".form-check input").forEach((el) => {
			el.addEventListener("change", function() {
				const checkbox_name =
					this.getAttribute("id") === null ||
						this.getAttribute("id") === undefined
						? document.title
						: this.getAttribute("id");


				if (this.checked === true && !this.classList.contains("completed")) {
					window.dataLayer.push({
						eventCategory: "Form",
						eventAction: "Field Completed",
						eventLabel: `Sign-Up Checkbox - ${checkbox_name}`,
						event: "e_form_progress",
					});
					this.classList.add("completed");
				} else if (this.checked === false) {
					window.dataLayer.push({
						eventCategory: "Form",
						eventAction: "Field Skipped",
						eventLabel: `Sign-Up Checkbox - ${checkbox_name}`,
						event: "e_form_progress",
					});
					this.classList.add("skipped");
				}
			});
		});
	}
	handleSelect(e) {
		let desc = "";
		switch (e) {
			case "I'm taking Doptelet now":
				desc = "ONDOPTELET";
				break;
			case "I'm taking a different treatment for my ITP":
				desc = "DIFFTREATMENT";
				break;
			case "I'm not currently on treatment for my ITP":
				desc = "NOTREATMENT";
				break;
			case "I'm caring for someone with ITP":
				desc = "CAREFORITP";
				break;
			default:
				break;
		}
    this.setState({ dropdownValue: e });
    this.setState({ description: desc });

		//FORM DATALAYER DROPDOWN
		const descriptionInput = document.querySelector(
			".form-group .formDropdown.descriptionDropdown"
		);
		if (e === "Select") {
			window.dataLayer.push({
				eventCategory: "Form",
				eventAction: "Field Skipped",
				eventLabel: `Sign-Up Dropdown - ${e}`,
				event: "e_form_progress",
			});
			descriptionInput.classList.add("skipped");
		} else {
			window.dataLayer.push({
				eventCategory: "Form",
				eventAction: "Field Completed",
				eventLabel: `Sign-Up Dropdown - ${e}`,
				event: "e_form_progress",
			});
			descriptionInput.classList.add("completed");
		}
	};
	handleTreatment(e) {
		let desc = "";
		switch (e) {
			case "I’m taking a different TPO-RA treatment":
				desc = "DIFFTREATMENT";
				break;
			case "I’m taking steroids, an immunosuppressant, or a SYK inhibitor":
				desc = "STEROIDS";
				break;
			default:
				break;
		}
		this.setState({ itpTreatment: e });
		this.setState({ itpDescription: desc });
	};
	filterStateSelect(e) {
		let states = document.querySelectorAll('.stateDropDown .states a');
		let input = e.target.value.toLowerCase();
		// No filter. Show all.
		if (!e.target.value) {
			states.forEach(function(state) {
				state.classList.remove('hide');
			});
		};

		states.forEach(function(state) {
			if (state.textContent.toLowerCase().includes(input)) {
				state.classList.remove('hide');
			} else {
				state.classList.add('hide');
			}
		});
	};
	handleStateSelect(e) {
		let state;
		switch (e) {
			case "Alabama":
				state = "AL";
				break;
			case "Alaska":
				state = "AK";
				break;
			case "Arizona":
				state = "AZ";
				break;
			case "Arkansas":
				state = "AR";
				break;
			case "California":
				state = "CA";
				break;
			case "Colorado":
				state = "CO";
				break;
			case "Connecticut":
				state = "CT";
				break;
			case "Delaware":
				state = "DE";
				break;
			case "District of Columbia":
				state = "DC";
				break;
			case "Florida":
				state = "FL";
				break;
			case "Georgia":
				state = "GA";
				break;
			case "Hawaii":
				state = "HI";
				break;
			case "Idaho":
				state = "ID";
				break;
			case "Illinois":
				state = "IL";
				break;
			case "Indiana":
				state = "IN";
				break;
			case "Iowa":
				state = "IA";
				break;
			case "Kansas":
				state = "KS";
				break;
			case "Kentucky":
				state = "KY";
				break;
			case "Louisiana":
				state = "LS";
				break;
			case "Maine":
				state = "ME";
				break;
			case "Maryland":
				state = "MD";
				break;
			case "Massachusetts":
				state = "MA";
				break;
			case "Michigan":
				state = "MI";
				break;
			case "Minnesota":
				state = "MN";
				break;
			case "Mississippi":
				state = "MS";
				break;
			case "Missouri":
				state = "MO";
				break;
			case "Montana":
				state = "MT";
				break;
			case "Nebraska":
				state = "NE";
				break;
			case "Nevada":
				state = "NV";
				break;
			case "New Hampshire":
				state = "NH";
				break;
			case "New Jersey":
				state = "NJ";
				break;
			case "New Mexico":
				state = "NM";
				break;
			case "New York":
				state = "NY";
				break;
			case "North Carolina":
				state = "NC";
				break;
			case "North Dakota":
				state = "ND";
				break;
			case "Ohio":
				state = "OH";
				break;
			case "Oklahoma":
				state = "OK";
				break;
			case "Oregon":
				state = "OR";
				break;
			case "Pennsylvania":
				state = "PA";
				break;
			case "Puerto Rico":
				state = "PR";
				break;
			case "Rhode Island":
				state = "RI";
				break;
			case "South Carolina":
				state = "SC";
				break;
			case "South Dakota":
				state = "SD";
				break;
			case "Tennessee":
				state = "TN";
				break;
			case "Texas":
				state = "TX";
				break;
			case "Utah":
				state = "UT";
				break;
			case "Vermont":
				state = "VT";
				break;
			case "Virginia":
				state = "VA";
				break;
			case "Washington":
				state = "WA";
				break;
			case "West Virginia":
				state = "WV";
				break;
			case "Wisconsin":
				state = "WI";
				break;
			case "Wyoming":
				state = "WY";
				break;
			default:
				state = "NA";
				break;
		}
		this.setState({ stateDropdownValue: e });
		this.setState({ state: state });
		document.querySelector('.dropdown-menu').scrollTop = 0;
		//FORM DATALAYER DROPDOWN
		const stateDropDownInput = document.querySelector(
			".form-group .formDropdown.stateDropDown"
		);
		if (e === "State*") {
			window.dataLayer.push({
				eventCategory: "Form",
				eventAction: "Field Skipped",
				eventLabel: `Sign-Up Dropdown - ${e}`,
				event: "e_form_progress",
			});
			stateDropDownInput.classList.add("skipped");
		} else {
			window.dataLayer.push({
				eventCategory: "Form",
				eventAction: "Field Completed",
				eventLabel: `Sign-Up Dropdown - ${e}`,
				event: "e_form_progress",
			});
			stateDropDownInput.classList.add("completed");
		}
	};
	handleChange(event) {
		const { formData } = this.state;

		this.setState({
			formData: {
				...formData, // leave other values unchanged
				[event.target.name]: event.target.value, // update the changed value
			}
		})
	};
	handleSubmit(e) {
		// Prevent Duplicate submissions.
		if (this.state.isSubmitting) {
			return;
		}
    // Continue submitting.
		this.state.isSubmitting = true;
		let yourName = this.state.YourName;
		let email = this.state.email;
		let mobile = this.state.mobile;
		let descriptionAlias = this.state.description;
    let descriptionText = this.state.dropdownValue;
    let itpTreatment = this.state.itpTreatment;
		let state = this.state.state;
		let address = this.state.address;
		let address2 = this.state.address2;
		let zip = this.state.zip;
		let city = this.state.city;
		let termsCheckValue = this.state.termsCheckValue ? "CHECKED" : "UNCHECKED";
		let urlParams = window.location.search.substring(1);

		if (validate(e, 'sign-up')) {
			window.dataLayer.push({
				'event': 'e_form_submit',
				'eventCategory': 'Form',
				'eventAction': 'Submit',
				'eventLabel': "Sign-Up"
			});
			e.preventDefault();
      if (itpTreatment == "I’m taking steroids, an immunosuppressant, or a SYK inhibitor") {
        descriptionAlias = 'ALTTREATMENT';
      }
      if (itpTreatment == '- - Choose - -') {
        itpTreatment = '';
      }
			axios({
				method: "post",
				url: '/service.php',
				headers: {
					'content-type': 'application/json'
				},
				data: {
					yourName,
					email,
					mobile,
          descriptionAlias,
					descriptionText,
          itpTreatment,
					state,
					address,
					address2,
					zip,
					city,
					termsCheckValue,
					urlParams,
				}
			})
				.then(result => {
					this.state.isSubmitting = false;
					if (result.data.eventInstanceId) {
						this.setState({ isValid: true });
						if (document.querySelector('.submit-error').classList.contains('show')) {
							document.querySelector('.submit-error').classList.add('show');
						}
					} else {
						document.querySelector('.submit-error').classList.add('show');
					}
				})
				.catch(error => {
					this.state.isSubmitting = false;
				});
		} else {
			e.preventDefault();
		}
	};
	render() {
		if (!this.state.isValid) {
			return (
				<div className={this.state.validated && !this.state.isValid ? 'form_wrapper hasErrors' : 'form_wrapper'}>
					<GoogleReCaptchaProvider
						reCaptchaKey="6LfcafYZAAAAALR2odRkqSuTIibO0AlSVCwnFj3J"
					>
						<p className="required-error">*Indicates required field</p>
						<Form id="sign-up" noValidate onSubmit={(e) => { this.handleSubmit(e); }}>
							<div className="inputs d-lg-flex">
								<div className="col-lg-6">
									<Form.Group controlId="YourName">
										<Form.Label><strong>Your name</strong>*</Form.Label>
										<Form.Control type="text" name="YourName" onChange={(e) => { this.state.validated && (validateText(e.target)); this.setState({ [e.target.name]: e.target.value }) }} required />
										<Form.Control.Feedback type="invalid">
											Please enter your name.
										</Form.Control.Feedback>
									</Form.Group>
									<Form.Group controlId="description">
										<Form.Label><strong>What best describes you?</strong>*</Form.Label>
										<DropdownButton
											title={this.state.dropdownValue}
											className="formDropdown descriptionDropdown"
											onSelect={(e) => { this.state.validated && validateDropdown(e, 'sign-up'); this.handleSelect(e) }}
											required
										>
											<Dropdown.Item eventKey="I'm taking Doptelet now">I'm taking Doptelet now</Dropdown.Item>
											<Dropdown.Item eventKey="I'm taking a different treatment for my ITP">I'm taking a different treatment for my ITP</Dropdown.Item>
											<Dropdown.Item eventKey="I'm not currently on treatment for my ITP">I'm not currently on treatment for my ITP</Dropdown.Item>
											<Dropdown.Item eventKey="I'm caring for someone with ITP">I'm caring for someone with ITP</Dropdown.Item>
										</DropdownButton>
										<Form.Control.Feedback type="invalid">
											Please make a selection.
										</Form.Control.Feedback>
									</Form.Group>
									<Form.Group controlId="itp-treatment" className={this.state.description == "DIFFTREATMENT" ? 'd-block' : 'd-none'}>
										<Form.Label><strong>Which best describes the ITP treatment you’re taking?</strong>*</Form.Label>
										<DropdownButton
											title={this.state.itpTreatment}
											className="formDropdown itpTreatmentDropdown"
											onSelect={(e) => { this.state.validated && validateTreatment(e, 'sign-up'); this.handleTreatment(e) }}
											required
										>
											<Dropdown.Item eventKey="I’m taking a different TPO-RA treatment">I’m taking a different TPO-RA treatment</Dropdown.Item>
											<Dropdown.Item eventKey="I’m taking steroids, an immunosuppressant, or a SYK inhibitor">I’m taking steroids, an immunosuppressant, or a SYK inhibitor</Dropdown.Item>
										</DropdownButton>
										<Form.Control.Feedback type="invalid">
											Please make a selection.
										</Form.Control.Feedback>
									</Form.Group>
								</div>
								<div className="col-lg-6">
									<Form.Group controlId="email" className="email-group">
										<Form.Label><strong>Email address</strong>*</Form.Label>
										<Form.Control type="email" placeholder="your@email.com" name="email" onChange={(e) => { this.state.validated && (validateEmail(e.target)); this.setState({ [e.target.name]: e.target.value }) }} required />
										<div className="email-input"></div>
										<Form.Control.Feedback type="invalid">
											Please enter a valid email address.
										</Form.Control.Feedback>
									</Form.Group>
									<Form.Group controlId="mobile">
										<Form.Label><strong>Phone number</strong> <em>(Optional)</em></Form.Label>
										<Form.Control type="text" placeholder="(123) 456-7890" name="mobile" onChange={(e) => { this.state.validated && (validateMobile(e.target)); this.setState({ [e.target.name]: e.target.value }) }} />
										<Form.Control.Feedback type="invalid">
											Please enter valid cell phone number.
										</Form.Control.Feedback>
										{this.state.mobile.length > 0 ? (
											<p><small>By providing your mobile number, you agree to receive marketing text messages from Sobi. Consent is not required to purchase goods or services. For more information, please see our <a href="/sms-terms" target="_blank">SMS Terms of Service</a>.</small></p>
										) : null}
									</Form.Group>
								</div>
							</div>
							<div className="inputs d-lg-flex">
								<div className="col-lg-6">
									<Form.Group controlId="address">
										<Form.Label><strong>Address</strong>*</Form.Label>
										<Form.Control type="text" name="address" onChange={(e) => { this.state.validated && (validateText(e.target)); this.setState({ [e.target.name]: e.target.value }) }} required />
										<Form.Control.Feedback type="invalid">
											Please enter valid address.
										</Form.Control.Feedback>
									</Form.Group>
									<Form.Group controlId="address2" className="d-none">
										<Form.Label><strong>Address 2</strong></Form.Label>
										<Form.Control type="text" name="address2" onChange={(e) => { this.setState({ [e.target.name]: e.target.value }) }} />
										<Form.Control.Feedback type="invalid">
											Please enter valid address.
										</Form.Control.Feedback>
									</Form.Group>
									<a onClick={showAddressTwo} href="#" className="show-address-two">Add address line 2</a>
								</div>
								<div className="col-lg-6">
									<Form.Group controlId="city">
										<Form.Label><strong>City</strong>*</Form.Label>
										<Form.Control type="text" name="city" onChange={(e) => { this.state.validated && (validateText(e.target)); this.setState({ [e.target.name]: e.target.value }) }} required />
										<Form.Control.Feedback type="invalid">
											Please enter city.
										</Form.Control.Feedback>
									</Form.Group>
								</div>
							</div>
							<div className="inputs d-lg-flex">
								<div className="col-lg-6">
									<Form.Group controlId="state">
										<Form.Label><strong>State</strong>*</Form.Label>
										<DropdownButton
											title={this.state.stateDropdownValue}
											className="formDropdown stateDropDown"
											onSelect={(e) => { this.state.validated && validateStateDropdown(e, 'sign-up'); this.handleStateSelect(e) }}
											required
										>
											<Form.Control
												autoFocus
												type="text"
												autoComplete="off"
												className="state-filter"
												placeholder="Type to filter..."
												onChange={(e) => this.filterStateSelect(e)}
											/>
											<div className="states">
												{this.stateItems}
											</div>
										</DropdownButton>
										<Form.Control.Feedback type="invalid">
											Please select a state.
										</Form.Control.Feedback>
									</Form.Group>
								</div>
								<div className="col-lg-6">
									<Form.Group controlId="zip">
										<Form.Label><strong>ZIP code</strong>*</Form.Label>
										<Form.Control type="text" pattern="(d{5}$)|(^\d{5}-\d{4})" name="zip" onChange={(e) => { this.state.validated && (validateZip(e.target)); this.setState({ [e.target.name]: e.target.value }) }} required />
										<Form.Control.Feedback type="invalid">
											Please enter valid ZIP code.
										</Form.Control.Feedback>
									</Form.Group>
								</div>
							</div>

							<Form.Check
								type="checkbox"
								id="agree"
								className="agree-to-terms"
							>
								<Form.Check.Input type="checkbox" onChange={(e) => { this.state.validated && validateCheckbox(e); this.setState({ termsCheckValue: this.state.termsCheckValue ? false : true }) }} required />
								<Form.Check.Label>
									<span>I’m at least 18 years old and I agree to the Sobi, Inc. <a href='https://sobi-northamerica.com/terms-and-conditions' target='_blank' rel="noopener noreferrer" >Terms and Conditions</a> and <a href='https://sobi-northamerica.com/site-privacy-policy' target='_blank' rel="noopener noreferrer" >Privacy&nbsp;Policy</a>.*</span>
								</Form.Check.Label>
								<Form.Control.Feedback type="invalid">
									Please indicate that you accept the terms
									and conditions.
								</Form.Control.Feedback>
							</Form.Check>
							<p className="text-grey pl-0 size-16"><br />Sobi, Inc. may use my information for market research or to evaluate and improve the company’s services and programs. I understand that I may stop Sobi, Inc. from contacting me at any time by clicking the “Unsubscribe” link in the emails I receive from Sobi, Inc. I understand that Sobi, Inc. will not sell or rent my personally identifiable information.</p>
							<Row>
								<div className="invalid-feedback submit-error">Something went wrong, please try again later</div>
							</Row>
							<Button variant="primary" className="submit-btn btn-arrow" type="submit" onClick={(e) => { this.setState({ validated: true }); validate(e, 'sign-up') }}>Sign Me Up</Button>
						</Form>
					</GoogleReCaptchaProvider>
				</div>
			);
		}
		if (this.state.isValid) {
			return <Redirect to="/thank-you" />
		}
	}
};

export default SignUpForm;
