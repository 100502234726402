import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap/esm/index";

// Import Components
import ITPHeroHeader from "../../components/HeroHeader/ITPHeroHeader";
import Accordion from "../../components/shared-ui/Accordion/Accordion";
import ItpNavigation from "../../components/ITPNavigation/ITPNavigation";
import ISIITPinline from "../../components/shared-ui/ISI/ISIITP-inline";
import FooterITP from "../../components/shared-ui/Footer/Footer-ITP";
import BackToTopBtn from "../../components/shared-ui/BackToTopBtn/BackToTopBtn";
import ISIwrapper from "../../components/shared-ui/ISI/ISI-wrapper";
import ISIITP from "../../components/shared-ui/ISI/ISIITP";
import YoutubeEmbed from "../../components/shared-ui/Video/YoutubeEmbed";

import "../../css/ConsideringDoptelet.scss";

//SEO
import Helmet from "react-helmet";
import { helmetJsonLdProp } from "react-schemaorg";
import PrimaryButton from "../../components/shared-ui/Buttons/PrimaryButton";

function showTermsModal(event) {
  event.preventDefault();
  let modal = document.getElementById("terms-modal");
  document.body.classList.add("no-scroll");
  modal.classList.remove("fadeOut");
  modal.style.display = "block";
  modal.classList.add("fadeIn");
  modal.style.height = "100vh";
  if (
    event.length &&
    event.currentTarget.classList.contains("scroll-to-terms")
  ) {
    const terms = document.getElementById("terms");
    const container = document.querySelector("#terms-modal .modal-container");
    container.scroll(0, terms.offsetTop);
  }
}

function ConsideringDoptelet() {
  useEffect(() => {
    window.scrollTo(0, 0);
    let location = window.location.pathname;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      page: {
        path: `${location}`,
        title: "What is DOPTELET® (avatrombopag) for ITP & CLD in Adults",
        environment: "dev",
        indication: "ITP",
        audience: "DTC",
      },
    });
  }, []);
  return (
    <div className="main_content_wrap">
      <Helmet
        script={[
          helmetJsonLdProp({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "Drug",
                name: "Doptelet",
                alternateName: "avatrombopag",
                manufacturer: {
                  "@type": "Organization",
                  name: "Sobi",
                },
                dosageForm: "Pill",
              },
              {
                "@type": "WebSite",
                about: {
                  "@type": "Thing",
                  description:
                    "To inform patients of Doptelet (avatrombopag), a prescription medicine used to treat low blood platelet counts in adults with chronic immune thrombocytopenia when other treatments have not worked well enough. Doptelet (avatrombopag) is also a prescription medicine used to treat low blood platelet counts in adults with long-lasting (chronic) liver disease who are scheduled to have a medical or dental procedure.",
                },
                url: "https://doptelethcp.com/itp/what-is-doptelet/",
              },
              {
                "@type": "Brand",
                logo: "https://doptelet.com/images/doptelet-avatrombopag-tablets-logo.svg",
              },
              {
                "@type": "Brand",
                logo: "https://doptelet.com/images/sobi-logo.png",
              },
              {
                url: "https://doptelet.com/what-is-doptelet/why-doptelet/",
                "@type": "Substance",
                name: "Avatrombopag",
                description:
                  "Avatrombopag is the active substance found in Doptelet, a medication used for the treatment of chronic immune thrombocytopenia (ITP) in certain adult patients.",
              },
              {
                "@type": "MedicalStudy",
                url: "https://doptelet.com/what-is-doptelet/why-doptelet/",
                name: "Clinical Trials for Doptelet",
                description:
                  "Explore the clinical trials conducted on Doptelet (avatrombopag) for the treatment of chronic immune thrombocytopenia (ITP) in adults.",
                studyLocation: [
                  {
                    "@type": "AdministrativeArea",
                    name: "United States",
                  },
                ],
                sponsor: {
                  "@type": "Organization",
                  name: "Dova Pharmaceuticals",
                },
              },
              {
                "@type": "MedicalIndication",
                url: "https://doptelet.com/what-is-doptelet/why-doptelet/",
                name: "Chronic Immune Thrombocytopenia (ITP) Treatment",
                description:
                  "Doptelet is indicated for the treatment of chronic immune thrombocytopenia (ITP) in certain adult patients.",
                medicineSystem: "http://purl.bioontology.org/ontology/RXNORM",
              },
              {
                "@type": "MedicalGuideline",
                url: "https://doptelet.com/what-is-doptelet/why-doptelet/",
                name: "Chronic Immune Thrombocytopenia (ITP) Treatment Guidelines",
                description:
                  "Explore the treatment guidelines for chronic immune thrombocytopenia (ITP) and learn about Doptelet as an approved medication for certain adult patients.",
                guidelineSubject: {
                  "@type": "MedicalEntity",
                  name: "Chronic immune thrombocytopenia (ITP)",
                },
                evidenceLevel: "Level B",
                evidenceOrigin: "Clinical trials and expert consensus",
                guidelineDate: "2022-01-01",
              },
              {
                "@type": "MedicalWebPage",
                url: "https://doptelet.com/what-is-doptelet/",
                name: "What is Doptelet?",
                description:
                  "Learn about Doptelet (avatrombopag), a medication approved for the treatment of chronic immune thrombocytopenia (ITP) in certain adult patients.",
              },
              {
                "@type": "MedicalTherapy",
                url: "https://doptelet.com/what-is-doptelet/",
                name: "Doptelet Usage",
                description:
                  "Learn about the usage of Doptelet (avatrombopag) for the treatment of chronic immune thrombocytopenia (ITP) in certain adult patients.",
                adverseOutcome:
                  "Common side effects may include nausea, fatigue, headache, and muscle or joint pain. Contact your healthcare provider if you experience severe or persistent adverse reactions.",
              },
              {
                "@type": "DrugClass",
                url: "https://doptelet.com/what-is-doptelet/",
                name: "Thrombopoietin Receptor Agonists",
                description:
                  "Thrombopoietin receptor agonists are a class of medications that stimulate the production and differentiation of megakaryocytes, leading to increased platelet production.",
                drug: {
                  "@type": "Drug",
                  name: "Doptelet",
                },
              },
            ],
          }),
        ]}>
        <title>
          ITP Medication to Increase Platelets | DOPTELET® (avatrombopag)
        </title>
        <meta
          name="description"
          content="Discover Doptelet - the one-pill solution for rapidly increasing platelet levels to 50,000 per microliter in just 8 days, with no dietary restrictions."
        />
        <link
          rel="canonical"
          href="https://doptelet.com/what-is-doptelet/why-doptelet/"
        />
      </Helmet>
      <ItpNavigation />
      <div className="inner-content-wrap about-doptelet">
        <ITPHeroHeader
          extraClass="large-hero inner-bg"
          markup={
            <>
              <h1>A One-Pill treatment to Increase Platelets in Chronic ITP</h1>
              <p className="mb-0">
                Did you know that the type of food you eat can impact the
                effectiveness of certain treatments? There’s only one pill that
                may raise your platelets to 50,000 per microliter in as little
                as 8 days—without placing any limits on when you can eat certain
                foods. It’s called Doptelet.
              </p>
            </>
          }
          maxWidth="515"
          bgImage={[
            "considering-doptelet-hero.jpg",
            "considering-doptelet-hero.jpg",
          ]}
          bgImageMobile={[
            "considering-doptelet-hero-mobile.jpg",
            "considering-doptelet-hero-mobile.jpg",
          ]}
          imageAlt="Doptelet platelet characters getting ice cream cones at a soft serve stand in carnival scenery"
        />

        <div className="pt-5">
          <Container>
            <Row className="align-items-center">
              <Col lg={{ span: 12 }}>
                <h2>A chronic ITP pill that stands out</h2>
                <p className="pr-lg-4">
                  With Doptelet, you don&lsquo;t need to worry about when you
                  can eat certain foods because of treatment. You can have dairy
                  before bed without setting the alarm at 5 AM to enjoy a bowl
                  of cereal for breakfast. It&lsquo;s totally up to you!
                </p>
                <p>
                  Plus, Doptelet isn&lsquo;t an injection&ndash;it&lsquo;s a
                  pill with no significant risk for liver damage or developing
                  cataracts. That means fewer visits to your doctor&lsquo;s
                  office for liver testing, eye exams, or treatment injections.
                </p>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="mt-5 pt-2 cloud-blue-bg dopteller-diaries video-section">
          <Container>
            <Row>
              <Col lg={5}>
                <h2>
                  Tammy&lsquo;s Tips: Getting the Most Out of Your
                  Doctor&lsquo;s Appointments
                </h2>
                <p className="pr-0">
                  Tammy, a real patient on Doptelet, discusses her top 4 tips to
                  help you get the most out of your doctor&lsquo;s appointments.
                </p>
                <div className="video-wrapper d-lg-none">
                  <YoutubeEmbed
                    embedId="BuIhQABk2-A"
                    image={["tammys_tips_getting_the_most.png"]}
                    imageMobile={["tammys_tips_getting_the_most.png"]}
                    alt="Play Video: Tammy's Tips: Getting the Most Out of Your Doctor's Appointments"
                  />
                </div>
                <PrimaryButton
                  title="MORE DOPTELET VIDEOS"
                  href="/support-and-resources/chronic-itp-videos/"
                  addClass="btn-transparent btn-arrow"
                />
              </Col>
              <Col lg={7} className="d-none d-lg-block">
                <div className="video-wrapper">
                  <YoutubeEmbed
                    embedId="BuIhQABk2-A"
                    image={[
                      "tammys_tips_getting_the_most.png",
                      "tammys_tips_getting_the_most@pngjpg",
                    ]}
                    imageMobile={[
                      "tammys_tips_getting_the_most.png",
                      "tammys_tips_getting_the_most@pngjpg",
                    ]}
                    alt="Play Video: Tammy's Tips: Getting the Most Out of Your Doctor's Appointments"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="bottom-page-ctas">
          <Container fluid>
            <Row>
              <Col md={6} className="dark-blue-bg d-flex flex-column tammy">
                <div className="content">
                  <div className="d-flex justify-content-center align-items-center gap-5 flex-grow-1">
                    <img
                      src="/images/circle_tammy.png"
                      className="mr-3 tammy"
                      alt="Portrait photo of Tammy, a Chronic ITP Patient taking Doptelet"
                    />
                    <h2>Get the real scoop on Doptelet from Tammy.</h2>
                  </div>
                  <p>
                    Tammy has been living with chronic ITP for 15 years and was
                    looking for a treatment that would lift platelets fast and
                    keep them stable. When it came to starting on Doptelet, she
                    only had one regret&mdash;that she didn&lsquo;t start
                    sooner. Watch Tammy&lsquo;s story.
                  </p>
                  <p className="mb-4">
                    <strong>
                      Interested in accessing tips and tools for managing ITP?
                      Sign up for updates today
                    </strong>
                  </p>
                  <PrimaryButton
                    title="SIGN ME UP"
                    href="/support-and-resources/living-with-itp/"
                    addClass="btn-transparent btn-white btn-arrow"
                  />
                </div>
              </Col>
              <Col md={6} className="d-flex flex-column">
                <div className="content">
                  <img
                    src="/images/platey-tossing-kid-3.png"
                    alt="Doptelet platelet character with beanie lifting up a small Doptelet platelet character"
                  />
                  <h2>
                    Now that you&lsquo;re in the know&mdash;take the big step
                    forward
                  </h2>
                  <p className="flex-grow-1">
                    Get started on a new chapter in your treatment journey.
                    Prepare for your next appointment by learning how to talk to
                    your doctor about managing your chronic ITP with Doptelet.
                  </p>
                  <PrimaryButton
                    title="MANAGING ITP"
                    href="/managing-itp/"
                    addClass="btn-arrow align-self-start"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <ISIITPinline />
      </div>
      <FooterITP />
      <ISIwrapper>
        <ISIITP />
      </ISIwrapper>
      <BackToTopBtn />
    </div>
  );
}

export default ConsideringDoptelet;
