import React, { useEffect } from "react";
import { helmetJsonLdProp } from "react-schemaorg";

import { Container, Row, Col } from "react-bootstrap/esm/index";
import Helmet from "react-helmet";
import { pushContactClick } from "../../assets/datalayerFunctions";

// Import Components
import TextOnlyHeroHeader from "../../components/HeroHeader/TextOnlyHeroHeader";
import ItpNavigation from "../../components/ITPNavigation/ITPNavigation";
import FooterITP from "../../components/shared-ui/Footer/Footer-ITP";
import BackToTopBtn from "../../components/shared-ui/BackToTopBtn/BackToTopBtn";
import ISIITPinline from "../../components/shared-ui/ISI/ISIITP-inline";
import ISIwrapper from "../../components/shared-ui/ISI/ISI-wrapper";
import ISIITP from "../../components/shared-ui/ISI/ISIITP";

// Import Styles
import "../../css/FinancialAssistance.scss";
import PrimaryButton from "../../components/shared-ui/Buttons/PrimaryButton";

function showTermsModal(event) {
  event.preventDefault();
  let modal = document.getElementById("terms-modal");
  document.body.classList.add("no-scroll");
  modal.classList.remove("fadeOut");
  modal.style.display = "block";
  modal.classList.add("fadeIn");
  modal.style.height = "100vh";
  if (
    event.length &&
    event.currentTarget.classList.contains("scroll-to-terms")
  ) {
    const terms = document.getElementById("terms");
    const container = document.querySelector("#terms-modal .modal-container");
    container.scroll(0, terms.offsetTop);
  }
}

function showTermsModalSimple() {
  let modal = document.getElementById("terms-modal");
  document.body.classList.add("no-scroll");
  modal.classList.remove("fadeOut");
  modal.style.display = "block";
  modal.classList.add("fadeIn");
  modal.style.height = "100vh";
}

function FinancialAssistance() {
  useEffect(() => {
    window.scrollTo(0, 0);
    let location = window.location.pathname;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      page: {
        path: `${location}`,
        title: "Doptelet Copay Assistance Program | DOPTELET® (avatrombopag)",
        environment: "dev",
        indication: "ITP",
        audience: "DTC",
      },
    });
    if (window.location.search.includes("terms-modal")) {
      showTermsModalSimple();
    }
  }, []);
  return (
    <div className="main_content_wrap">
      <Helmet
        script={[
          helmetJsonLdProp({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "Drug",
                name: "Doptelet",
                alternateName: "avatrombopag",
                manufacturer: {
                  "@type": "Organization",
                  name: "Sobi",
                },
                dosageForm: "Pill",
              },
              {
                "@type": "WebSite",
                about: {
                  "@type": "Thing",
                  description:
                    "To inform patients of Doptelet (avatrombopag), a prescription medicine used to treat low blood platelet counts in adults with chronic immune thrombocytopenia when other treatments have not worked well enough. Doptelet (avatrombopag) is also a prescription medicine used to treat low blood platelet counts in adults with long-lasting (chronic) liver disease who are scheduled to have a medical or dental procedure.",
                },
                url: "https://doptelethcp.com/itp/doptelet-copay/",
              },
              {
                "@type": "Brand",
                logo: "https://doptelet.com/images/doptelet-avatrombopag-tablets-logo.svg",
              },
              {
                "@type": "Brand",
                logo: "https://doptelet.com/images/sobi-logo.png",
              },
              {
                "@type": "MedicalAudience",
                audienceType:
                  "Patients with chronic immune thrombocytopenia when other treatments have not worked well enough. ",
                healthCondition: {
                  "@type": "MedicalCondition",
                  alternateName: "Immune Thrombocytopenia (ITP)",
                },
                requiredMinAge: "18",
              },
              {
                "@type": "HealthTopicContent",
                hasHealthAspect:
                  "The most common side effects of DOPTELET when used to treat low blood platelet counts in adults with chronic immune thrombocytopenia (ITP) are: headache, tiredness, bruising, nosebleed, upper-respiratory tract infection, joint pain, gum bleeding, purple or red spots on your skin, runny nose",
              },
              {
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 3,
                    item: {
                      "@id":
                        "https://doptelet.com/support-and-resources/financial-assistance/",
                      Name: "Find ITP Resources",
                    },
                  },
                ],
              },
            ],
          }),
        ]}>
        <title>
          Patient Financial Assistance, Copay Card & Trial Offer | DOPTELET®
          (avatrombopag)
        </title>
        <meta
          name="description"
          content="Discover financial assistance options to help manage the cost of chronic ITP treatment, like the Doptelet® copay card, free trial offer & more."
        />
        <link
          rel="canonical"
          href="https://doptelet.com/support-and-resources/financial-assistance/"
        />
      </Helmet>
      <ItpNavigation />
      <div className="inner-content-wrap financial">
        <TextOnlyHeroHeader
          markup={
            <>
              <h1>Financial Assistance Information</h1>
              <p>
                Let’s explore all of the Doptelet financial assistance options
                you can access to help manage the cost of chronic ITP treatment
                for low platelet counts, like the Doptelet copay card, free
                trial offer, and more.
              </p>
            </>
          }
        />

        <section className="pt-5 pb-5 scroll-to-section" id="copay-card">
          <Container>
            <Row>
              <Col md={{ span: 12 }}>
                <div className="img mb-4 text-center text-md-left">
                  <img
                    src="/images/pay_as_little_2024.svg"
                    alt="Pay as Little as $0 Doptelet Copay Icon"
                    style={{ maxWidth: 215 }}
                  />
                </div>
                <div className="content">
                  <h2 className="mb-3">
                    Are you eligible for the Doptelet Copay Assistance Program?
                  </h2>
                  <p className="mb-4">
                    You’re just 7 questions away from finding out! If you do
                    qualify, you&lsquo;ll be able to pay as little as $0 for
                    Doptelet.
                  </p>
                  <p>
                    <PrimaryButton
                      title="Enroll Now"
                      href="https://www.activatethecard.com/8015/?src=patient"
                      target="_blank"
                      addClass="btn-arrow"
                    />
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="pt-3">
          <Container>
            <Row>
              <Col md={{ span: 12 }}>
                <div className="img mb-4 text-center text-md-left">
                  <img
                    src="/images/free_trial_offer_sign.svg"
                    alt="Doptelet Free Trial Offer"
                    style={{ maxWidth: 200 }}
                  />
                </div>
                <div className="content mb-5">
                  <h2 className="mb-3">Try the Doptelet Free Trial Offer</h2>
                  <p>
                    Don’t want to financially commit to Doptelet before you know
                    whether it’s a good fit for your ITP treatment or not? This
                    free trial offer through Doptelet Connect will provide
                    eligible new patients with a free 15-day supply of Doptelet.
                    You can find the prescription and enrollment form (to be
                    filled out by your doctor) for it below.{" "}
                  </p>
                  <p>
                    <PrimaryButton
                      title="DOWNLOAD FORM"
                      href="/pdf/doptelet-prescription-enrollment-form.pdf"
                      addClass="btn btn-primary download btn-transparent"
                      target="_blank"
                    />
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section
          id="doptelet-connect"
          className="doptelet-connect-container pt-5 pb-5 scroll-to-section">
          <Container>
            <Row>
              <Col md={{ span: 12 }}>
                <img
                  src="/images/DopteletConnect_Logo@2x.png"
                  alt="Doptelet® Connect™ logo"
                />
                <h2 className="my-3">What is Doptelet Connect?</h2>
                <p>
                  <strong>
                    Doptelet Connect helps assist your doctor and their office
                    to get patients their prescription of Doptelet
                  </strong>
                </p>
                <p>
                  Doptelet Connect is one of several ways you can access
                  treatment with Doptelet. Its Dedicated Care Coordinators will
                  work with you and your doctor to find a pharmacy that can fill
                  your prescription.
                </p>
                <p>Here&lsquo;s how it works:</p>
                <div className="step d-flex align-items-center">
                  <div className="number-and-icon d-flex justify-content-center">
                    <span>1</span>
                    <img
                      src="/images/icon-prescription.svg"
                      alt="Paper with pen icon representing Doptelet prescription or patient enrollment forms"
                    />
                  </div>
                  <div className="copy">
                    <p className="mb-0">
                      <strong>
                        Your doctor&lsquo;s office can send your prescription to
                        Doptelet Connect for review.
                      </strong>
                    </p>
                  </div>
                </div>
                <div className="step d-flex flex-column flex-md-row align-items-start">
                  <div className="number-and-icon d-flex justify-content-center">
                    <span>2</span>
                    <img
                      src="/images/icon-representative.svg"
                      alt="Silhouette of person with headset on icon"
                    />
                    <p className="d-md-none">
                      <strong>Dedicated Care Coordinators can help:</strong>
                    </p>
                  </div>
                  <div className="copy mt-3 mt-md-0">
                    <p className="d-none d-md-block">
                      <strong>Dedicated Care Coordinators can help:</strong>
                    </p>
                    <ul>
                      <li className="mb-3">
                        <strong>Research</strong> your insurance plan
                      </li>
                      <li className="mb-3">
                        <strong>Coordinate</strong> with a pharmacy that can
                        fill your Doptelet prescription
                      </li>
                      <li className="mb-3">
                        <strong>Connect</strong> with you to identify financial
                        assistance options, if needed. You must meet{" "}
                        <a
                          href="#discover"
                          onClick={showTermsModal}
                          className="text-bold text-underline">
                          eligibility criteria
                        </a>
                      </li>
                    </ul>
                    <p>
                      <strong>For more information, call</strong>{" "}
                      1-833-368-2663.
                    </p>
                    <p className="mb-0">
                      <strong>
                        Care Coordinators are available Monday&ndash;Friday 8{" "}
                        <span className="small-caps">AM</span>&ndash;8{" "}
                        <span className="small-caps">PM</span> ET.
                      </strong>
                    </p>
                  </div>
                </div>
                <div className="step d-flex align-items-center">
                  <div className="number-and-icon d-flex justify-content-center">
                    <span>3</span>
                    <img
                      src="/images/icon-pharmacy.svg"
                      alt="Rx prescription icon"
                    />
                  </div>
                  <div className="copy">
                    <p className="mb-0">
                      <strong>
                        A pharmacy will contact you to confirm your delivery
                        details before sending you Doptelet.
                      </strong>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="pb-4 pt-5">
          <Container>
            <Row>
              <Col md={{ span: 12 }}>
                <h2>Doptelet Connect Patient Resources</h2>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="doptelet-connect-resources pb-md-4">
          <Container>
            <Row>
              <Col lg={{ span: 6 }} className="mb-3 mb-lg-0">
                <div className="cloud-blue-bg d-flex flex-column h-100">
                  <div className="d-flex align-items-center w-100">
                    <img
                      src="/images/icon_prescription.svg"
                      alt="Prescription pad Icon"
                      className="mr-4"
                    />
                    <strong>Enrollment and Prescription Form</strong>
                  </div>
                  <p>
                    Find out what information you’ll need to get copay support
                    through Doptelet Connect.
                  </p>
                  <p>
                    <PrimaryButton
                      title="DOWNLOAD FORM"
                      href="/pdf/doptelet-prescription-enrollment-form.pdf"
                      addClass="btn btn-primary download btn-transparent"
                      target="_blank"
                    />
                  </p>
                </div>
              </Col>
              <Col lg={{ span: 6 }}>
                <div className="cloud-blue-bg d-flex flex-column h-100">
                  <div className="d-flex align-items-center w-100">
                    <img
                      src="/images/icon_prescription.svg"
                      alt="Prescription pad Icon"
                      className="mr-4"
                    />
                    <strong>Patient/Caregiver Consent Form</strong>
                  </div>
                  <p className="flex-grow-1">
                    Enroll yourself in Doptelet Connect today.
                  </p>
                  <p>
                    <PrimaryButton
                      title="DOWNLOAD FORM"
                      href="/pdf/patient-caregiver-enrollment-consent-form.pdf"
                      addClass="btn btn-primary download btn-transparent"
                      target="_blank"
                    />
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="doptelet-copay-program pb-5 pt-4">
          <Container>
            <Row>
              <Col md={{ span: 12 }}>
                <picture>
                  <source
                    srcSet="/images/platey_Kissing.png 1x, /images/platey_Kissing_@2X.png 2x"
                    type="image/png"
                  />
                  <img
                    src="/images/platey_Kissing.png"
                    alt="Doptelet Platelet Characters kissing"
                  />
                </picture>
                <h2 className="mb-4">
                  Discover the Doptelet Copay Assistance Program
                </h2>
                <p className="mb-4">
                  <strong>
                    Eligible commercially insured patients may qualify for the
                    Doptelet Connect program.
                  </strong>
                </p>
                <p>
                  <strong>What’s the program?</strong>
                </p>
                <ul>
                  <li>
                    Eligible patients may pay as little as $0 for each Doptelet
                    prescription
                  </li>
                  <li>Annual maximum benefit up to $15,000</li>
                </ul>
                <p className="mt-4">
                  <strong>Eligible patients must:</strong>
                </p>
                <ul>
                  <li>Have commercial insurance that covers Doptelet</li>
                  <li>
                    Not be enrolled in any state or federal healthcare program
                    such as Medicare, Medicaid, Medigap, Veterans Affairs (VA),
                    Department of Defense (DOD), or TRICARE
                  </li>
                  <li>Be 18 years of age or older</li>
                  <li>Be a resident of the United States or a US Territory</li>
                </ul>
                <p className="mt-4">
                  <strong>How to enroll:</strong>
                </p>
                <ul>
                  <li>
                    Approved pharmacies, healthcare professionals, and Doptelet
                    Connect can enroll patients
                  </li>
                  <li>
                    Patients can enroll themselves or be enrolled by their
                    caregivers{" "}
                  </li>
                  <li>
                    Please call&nbsp;
                    <a
                      href="tel:+18333682663"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="no-break-mobile"
                      onClick={pushContactClick}>
                      <strong>1&#45;833&#45;368&#45;2663</strong>
                    </a>{" "}
                    Monday–Friday 8 AM–8 PM ET for more information
                  </li>
                </ul>
                <p className="mt-4">
                  <strong>Terms and Conditions </strong>
                </p>
                <ul className="no-margin-bottom">
                  <li>
                    Patients pay as little as $0 per prescription. Maximum
                    benefit of up to $5,000 per Doptelet prescription. Patient
                    is responsible for any remaining out-of-pocket costs that
                    exceed $5,000 per prescription. Copay Assistance Program has
                    an annual calendar cap of $15,000
                  </li>
                  <li>
                    The Copay Assistance Program is void where prohibited by
                    law, taxes, or restricted
                  </li>
                  <li>
                    This offer is non-transferable, no substitutions are
                    permissible, and this offer cannot be combined with any
                    other rebate/coupon, free trial, or similar offer for the
                    specified prescription
                  </li>
                  <li>
                    Sobi, Inc. reserves the right to rescind, revoke, or amend
                    this offer at any time without notice
                  </li>
                  <li>
                    The Copay Assistance Program for Doptelet is not insurance
                    and is not intended to substitute for insurance
                  </li>
                  <li>
                    Patients, pharmacists, and healthcare providers must not
                    seek reimbursement from health insurance or any third party
                    for any part of the benefit received by the patient through
                    this Copay Assistance Program. Patients must not seek
                    reimbursement from any health savings, flexible spending, or
                    other healthcare reimbursement accounts for the amount of
                    assistance received from the Copay Assistance Program
                  </li>
                  <li>
                    Certain information pertaining to your use of the Copay
                    Assistance Program will be shared with Sobi, Inc., the
                    sponsor of the Copay Assistance Program. The information
                    disclosed will include the date the prescription is filled,
                    the number of pills or product dispensed by the pharmacists,
                    and the amount of your copay that will be paid for by using
                    this Copay Assistance Program. For more information, please
                    see the Sobi Privacy Policy at
                    https://sobi-northamerica.com/privacy-policy
                  </li>
                  <li>
                    Acceptance in this Copay Assistance Program is not
                    conditioned on any past, present, or future purchase,
                    including additional doses
                  </li>
                  <li>
                    Enrollment for the Copay Assistance Program is valid through
                    the calendar year
                  </li>
                  <li className="no-margin-bottom">
                    Sobi, Inc. reserves the right to change program terms at any
                    time
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="cloud-blue-bg">
          <picture className="m-auto d-md-none">
            <img
              src="/images/Platey_Balloon_Store.jpg"
              alt="Doptelet Platelet Character at Carnival Game"
              className="img-responsive"
            />
          </picture>

          <Container>
            <Row>
              <Col md={{ span: 12 }}>
                <Row>
                  <Col md={6} className="py-5 py-md-3 align-content-center">
                    <h2>Need more chronic ITP resources?</h2>
                    <p>
                      There are so many resources for you! Check out our helpful
                      handouts and see which speak to you.
                    </p>
                    <PrimaryButton
                      title="CHRONIC ITP RESOURCES"
                      href="/support-and-resources/itp-patient-resources/"
                      addClass="btn-arrow"
                    />
                  </Col>
                  <Col md={6} className="d-none d-md-block py-5">
                    <picture>
                      <img
                        src="/images/Platey_Balloon_Store.jpg"
                        alt="Doptelet Platelet Character at Carnival Game"
                        className="img-responsive"
                      />
                    </picture>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>

        <ISIITPinline />
      </div>
      <FooterITP />
      <ISIwrapper>
        <ISIITP />
      </ISIwrapper>
      <BackToTopBtn />
    </div>
  );
}

export default FinancialAssistance;
