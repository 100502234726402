import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap/esm/index";
import Helmet from "react-helmet";
import { helmetJsonLdProp } from "react-schemaorg";

// Import Components
import HalfTextHalfImageHero from "../../components/HeroHeader/HalfTextHalfImageHero";
import ITPHeroHeader from "../../components/HeroHeader/ITPHeroHeader";
import Accordion from "../../components/shared-ui/Accordion/Accordion";
import ItpNavigation from "../../components/ITPNavigation/ITPNavigation";
import FooterITP from "../../components/shared-ui/Footer/Footer-ITP";
import BackToTopBtn from "../../components/shared-ui/BackToTopBtn/BackToTopBtn";
import ISIITPinline from "../../components/shared-ui/ISI/ISIITP-inline";
import ISIwrapper from "../../components/shared-ui/ISI/ISI-wrapper";
import ISIITP from "../../components/shared-ui/ISI/ISIITP";
import YoutubeEmbed from "../../components/shared-ui/Video/YoutubeEmbed";
import PrimaryButton from "../../components/shared-ui/Buttons/PrimaryButton";

function SupportAndResources() {
  useEffect(() => {
    window.scrollTo(0, 0);
    let location = window.location.pathname;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      page: {
        path: `${location}`,
        title: "ITP Support & Resources | DOPTELET® (avatrombopag)",
        environment: "dev",
        indication: "ITP",
        audience: "DTC",
      },
    });
  }, []);

  return (
    <div className="main_content_wrap">
      <Helmet>
        <title>
          Chronic ITP Patient Support & Resources | DOPTELET® (avatrombopag)
        </title>
        <meta
          name="description"
          content="Access Doptelet's support and resources for chronic ITP, like tips for managing ITP, patient brochures, financial assistance options & more."
        />
        <link
          rel="canonical"
          href="https://doptelet.com/support-and-resources/"
        />
      </Helmet>
      <ItpNavigation />
      <div className="inner-content-wrap">
        <ITPHeroHeader
          extraClass="large-hero inner-bg"
          markup={
            <>
              <h1>ITP SUPPORT &amp; RESOURCES</h1>
              <p className="mb-0">
                Looking to learn more about how to access treatment with
                Doptelet? You’ve come to the right place.
              </p>
            </>
          }
          maxWidth="513"
          bgImage={["support-resources-hero.jpg", "support-resources-hero.jpg"]}
          bgImageMobile={[
            "support-resources-hero-mobile.jpg",
            "support-resources-hero-mobile.jpg",
          ]}
          imageAlt="Doptelet platelet character with son at carnival game"
        />

        <section className="pt-4 pt-lg-5 pb-4 pb-lg-5">
          <Container>
            <Row>
              <Col>
                <h2>What kind of support do you need?</h2>
                <p>
                  From copay to community, there&lsquo;s a resource for
                  everyone. Take a look below and see how easy starting Doptelet
                  can be.
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="pb-5">
          <Container>
            <Row className="mb-lg-4">
              <Col md={12} lg={4} className="mb-4 mb-lg-0">
                <a
                  href="/support-and-resources/financial-assistance/"
                  className="page-cta-link cloud-blue-bg">
                  <div className="d-flex align-items-center">
                    <img
                      src="/images/financial-assistance-icon.png"
                      alt="Search bar with mouse icon representing Doptelet financial assistance programs"
                    />
                    <h4 className="text-orange">
                      <strong>FINANCIAL ASSISTANCE</strong>
                    </h4>
                  </div>
                  <p>
                    Discover programs that might help you save on treatment,
                    such as the Copay Assistance Program or Free Trial Offer.
                  </p>
                  <img
                    src="/images/arrow-icon.svg"
                    className="d-block ml-auto"
                    alt="Arrow pointing right icon"
                  />
                </a>
              </Col>
              <Col md={12} lg={4} className="mb-4 mb-lg-0">
                <a
                  href="/support-and-resources/itp-patient-resources/"
                  className="page-cta-link cloud-blue-bg">
                  <div className="d-flex align-items-center">
                    <img
                      src="/images/resources-icon.png"
                      alt="Doptelet Patient Information and Resources Magnifying Glass Icon"
                    />
                    <h4 className="text-orange">
                      <strong>CHRONIC ITP RESOURCES</strong>
                    </h4>
                  </div>
                  <p>
                    Looking for lifestyle support? Informational downloads,
                    support organizations, and more are waiting for you.
                  </p>
                  <img
                    src="/images/arrow-icon.svg"
                    className="d-block ml-auto"
                    alt="Arrow pointing right icon"
                  />
                </a>
              </Col>
              <Col md={12} lg={4} className="mb-4 mb-lg-0">
                <a
                  href="/support-and-resources/chronic-itp-videos/"
                  className="page-cta-link cloud-blue-bg">
                  <div className="d-flex align-items-center">
                    <img
                      src="/images/video-icon.png"
                      alt="Playback icon representing Doptelet ITP video resources for patients"
                    />
                    <h4 className="text-orange">
                      <strong>ITP VIDEO RESOURCES</strong>
                    </h4>
                  </div>
                  <p>
                    Hear real stories about treating low platelets in chronic
                    ITP with Doptelet.
                  </p>
                  <img
                    src="/images/arrow-icon.svg"
                    className="d-block ml-auto"
                    alt="Arrow pointing right icon"
                  />
                </a>
              </Col>
            </Row>
            <Row>
              <Col md={12} lg={4} className="mb-4 mb-lg-0">
                <a
                  href="/support-and-resources/living-with-itp/"
                  className="page-cta-link cloud-blue-bg">
                  <div className="d-flex align-items-center">
                    <img
                      src="/images/tips-icon.png"
                      alt="Icon representing doctor discussion guide and chronic itp tips documents for patients"
                    />
                    <h4 className="text-orange">
                      <strong>GET CHRONIC ITP TIPS</strong>
                    </h4>
                  </div>
                  <p>
                    Looking for Doptelet and chronic immune thrombocytopenia
                    tips and tools? Join to stay in the loop on Doptelet today.
                  </p>
                  <img
                    src="/images/arrow-icon.svg"
                    className="d-block ml-auto"
                    alt="Arrow pointing right icon"
                  />
                </a>
              </Col>
              <Col md={12} lg={4}>
                <a
                  href="/support-and-resources/share-your-story/"
                  className="page-cta-link cloud-blue-bg">
                  <div className="d-flex align-items-center">
                    <img
                      src="/images/share-story-icon.png"
                      alt="Silhouette of person with headset on icon"
                    />
                    <h4 className="text-orange">
                      <strong>SHARE YOUR STORY</strong>
                    </h4>
                  </div>
                  <p>Got a story to share about your chronic ITP?</p>
                  <img
                    src="/images/arrow-icon.svg"
                    className="d-block ml-auto"
                    alt="Arrow pointing right icon"
                  />
                </a>
              </Col>
            </Row>
          </Container>
        </section>

        <ISIITPinline />
      </div>
      <FooterITP />
      <ISIwrapper>
        <ISIITP />
      </ISIwrapper>
      <BackToTopBtn />
    </div>
  );
}

export default SupportAndResources;
