import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap/esm/index";
import Helmet from "react-helmet";
import { helmetJsonLdProp } from "react-schemaorg";

// Import Components
import HalfTextHalfImageHero from "../../components/HeroHeader/HalfTextHalfImageHero";
import ItpNavigation from "../../components/ITPNavigation/ITPNavigation";
import FooterITP from "../../components/shared-ui/Footer/Footer-ITP";
import BackToTopBtn from "../../components/shared-ui/BackToTopBtn/BackToTopBtn";
import ISIITPinline from "../../components/shared-ui/ISI/ISIITP-inline";
import ISIwrapper from "../../components/shared-ui/ISI/ISI-wrapper";
import ISIITP from "../../components/shared-ui/ISI/ISIITP";
import YoutubeEmbed from "../../components/shared-ui/Video/YoutubeEmbed";
import PrimaryButton from "../../components/shared-ui/Buttons/PrimaryButton";
import Resource from "../../components/PatientResources/Resource";

// Import Styles
import "../../css/TalkToYourDoctor.scss";

function TalkToYourDoctor() {
  useEffect(() => {
    window.scrollTo(0, 0);
    let location = window.location.pathname;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      page: {
        path: `${location}`,
        title:
          "Talk to Your Doctor About ITP Treatment Goals | DOPTELET® (avatrombopag)",
        environment: "dev",
        indication: "ITP",
        audience: "DTC",
      },
    });
  }, []);
  return (
    <div className="main_content_wrap">
      <Helmet
        script={[
          helmetJsonLdProp({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "Drug",
                name: "Doptelet",
                alternateName: "avatrombopag",
                manufacturer: {
                  "@type": "Organization",
                  name: "Sobi",
                },
                dosageForm: "Pill",
              },
              {
                "@type": "WebSite",
                about: {
                  "@type": "Thing",
                  description:
                    "To inform patients of Doptelet (avatrombopag), a prescription medicine used to treat low blood platelet counts in adults with chronic immune thrombocytopenia when other treatments have not worked well enough. Doptelet (avatrombopag) is also a prescription medicine used to treat low blood platelet counts in adults with long-lasting (chronic) liver disease who are scheduled to have a medical or dental procedure.",
                },
                url: "https://doptelethcp.com/itp/itp-treatment-goals/",
              },
              {
                "@type": "Brand",
                logo: "https://doptelet.com/images/doptelet-avatrombopag-tablets-logo.svg",
              },
              {
                "@type": "Brand",
                logo: "https://doptelet.com/images/sobi-logo.png",
              },
              {
                "@type": "MedicalAudience",
                audienceType:
                  "Patients with chronic immune thrombocytopenia when other treatments have not worked well enough. ",
                healthCondition: {
                  "@type": "MedicalCondition",
                  alternateName: "Immune Thrombocytopenia (ITP)",
                },
                requiredMinAge: "18",
              },
              {
                "@type": "HealthTopicContent",
                hasHealthAspect:
                  "The most common side effects of DOPTELET when used to treat low blood platelet counts in adults with chronic immune thrombocytopenia (ITP) are: headache, tiredness, bruising, nosebleed, upper-respiratory tract infection, joint pain, gum bleeding, purple or red spots on your skin, runny nose",
              },
              {
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 3,
                    item: {
                      "@id": "https://doptelet.com/talk-to-your-doctor/",
                      Name: "Setting ITP Treatment Goals",
                    },
                  },
                ],
              },
            ],
          }),
        ]}>
        <title>
          Talk to Your Doctor About ITP Treatment Goals | DOPTELET®
          (avatrombopag)
        </title>
        <meta
          name="description"
          content="See how talking to your doctor about how DOPTELET® (avatrombopag) can help you set and meet chronic immune thrombocytopenia (ITP) treatment goals."
        />
        <link
          rel="canonical"
          href="https://doptelet.com/talk-to-your-doctor/"
        />
      </Helmet>
      <ItpNavigation />
      <div className="inner-content-wrap">
        <HalfTextHalfImageHero
          markup={
            <>
              <h1>Talk to Your Doctor</h1>
              <p>
                Living with low platelet counts in chronic immune
                thrombocytopenia is hard, but talking about it doesn’t have to
                be. Your voice matters—here’s how to use it to find your chronic
                ITP treatment fit.{" "}
              </p>
            </>
          }
          image={["3.0_hero.png", "3.0_hero_@2X.png"]}
          imageMobile={["3.0_hero.png", "3.0_hero_@2X.png"]}
          extraClass="talk-to-your-doctor"
          imageAlt="Doptelet Platetlet Doctor Character in Discussion"
        />

        <Container
          className="setting_treatment_section pb-3 pt-5 scroll-to-section"
          id="treatment-goals">
          <Row>
            <Col md={{ span: 10, offset: 1 }}>
              <div>
                <h2>Treatment goal discussion</h2>
                <p>
                  Having an open and honest conversation with your doctor can
                  help lead to care that best fits both your personal and
                  treatment needs.
                </p>
                <p>
                  Have other treatments not worked well enough for you? Is
                  chronic ITP impacting your day-to-day life? Here are a few
                  questions to ask yourself before starting the conversation
                  with your doctor:
                </p>
              </div>

              <Row>
                <Col>
                  <div className="d-flex align-items-start mt-4">
                    <img
                      src="/images/icon_health_goals.svg"
                      alt="ITP Treatment Health Goals Bullseye Icon"
                      className="mr-4 d-none d-md-block"
                    />
                    <div>
                      <p className="d-flex align-items-center">
                        <img
                          src="/images/icon_health_goals.svg"
                          alt="ITP Treatment Health Goals Bullseye Icon"
                          className="mr-4 d-md-none d-sm-inline-block"
                        />
                        <strong>Health goals</strong>
                      </p>
                      <p>
                        Everyone has their own likes and dislikes, which can
                        affect the treatment you and your doctor choose
                        together.
                      </p>
                      <ul>
                        <li>What is your most important treatment goal?</li>
                        <li>Why does that goal matter to you?</li>
                        <li>How do you feel about your current treatment?</li>
                        <li>
                          Is your current treatment helping you reach your goal?
                        </li>
                        <li>
                          Does your current treatment affect your day-to-day
                          life?
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="d-flex align-items-start">
                    <img
                      src="/images/icon_personal_life.svg"
                      alt="Heart Icon"
                      className="mr-4 d-none d-md-block"
                    />
                    <div>
                      <p className="d-flex align-items-center">
                        <img
                          src="/images/icon_personal_life.svg"
                          alt="Heart Icon"
                          className="mr-4 d-md-none d-sm-inline-block"
                        />
                        <strong>Personal preferences</strong>
                      </p>
                      <p>
                        Certain chronic ITP treatments require injections for
                        dosing or food-type restrictions (like ones on dairy or
                        iron supplements) that may disrupt your routine.
                      </p>
                      <ul>
                        <li>Are you worried about your platelet counts?</li>
                        <li>
                          Would you rather take a pill or get an injection for
                          dosing?
                        </li>
                        <li>
                          Would you be willing to plan your meals around your
                          treatment?
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="d-flex align-items-start">
                    <img
                      src="/images/icon_other_factors.svg"
                      alt="Calendar Schedule Icon"
                      className="mr-4 d-none d-md-block"
                    />
                    <div>
                      <p className="d-flex align-items-center">
                        <img
                          src="/images/icon_other_factors.svg"
                          alt="Calendar Schedule Icon"
                          className="mr-4 d-md-none d-sm-inline-block"
                        />
                        <strong>Other factors</strong>
                      </p>
                      <p>
                        It’s important to consider all of your options when
                        choosing a treatment, including if you can afford it,
                        how it fits in to your work schedule, and ease of
                        travel.
                      </p>
                      <ul>
                        <li>
                          Have you missed or had to skip any treatment doses in
                          the last few months? If so, why?
                        </li>
                        <li>
                          Do you regularly miss work to go to your doctor
                          appointments?
                        </li>
                        <li>
                          Are you able to get to your doctor appointments
                          easily? (think: car, bus, train, etc)
                        </li>
                        <li>
                          Are you having trouble affording your treatments?
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        <div className="cloud-blue-bg video-section">
          <Container>
            <Row className="align-items-center">
              <Col lg={{ span: 4, offset: 1 }}>
                <h2 className="d-lg-none mb-4">
                  Tammy’s Tips:
                  <br />
                  Important Questions to Ask Your Doctor About Setting Treatment
                  Goals
                </h2>
                <div className="video-wrapper">
                  <YoutubeEmbed
                    embedId="BuIhQABk2-A"
                    image={[
                      "tammys_tips_important_questions_D.png",
                      "tammys_tips_important_questions_@2X_D.png",
                    ]}
                    imageMobile={[
                      "tammys_tips_important_questions.png",
                      "tammys_tips_important_questions_@2X.png",
                    ]}
                    alt="Play Video: Tammy’s Tips: Important Questions to Ask Your Doctor About Setting Treatment Goals"
                  />
                </div>
              </Col>
              <Col lg={6}>
                <h2 className="d-lg-block d-none">
                  Tammy’s Tips:
                  <br />
                  Important Questions to Ask Your Doctor About Setting Treatment
                  Goals
                </h2>
                <p>Tammy is a real Doptelet patient.</p>
              </Col>
            </Row>
          </Container>
        </div>

        <section className="not-sure" id="question-bubbles">
          <Container>
            <Row>
              <Col md={{ span: 10, offset: 1 }}>
                <div className="d-flex align-items-center mb-5 mt-5 question-section">
                  <picture>
                    <source
                      media="(min-width: 992px)"
                      srcSet="/images/platey_Skipper_Jump_Rope_D.png 1x, /images/platey_Skipper_Jump_Rope_@2X_D.png 2x"
                      type="image/png"
                    />
                    <source
                      media="(max-width: 991px)"
                      srcSet="/images/platey_Skipper_Jump_Rope.png 1x, /images/platey_Skipper_Jump_Rope_@2X.png 2x"
                      type="image/png"
                    />
                    <img
                      src="/images/platey_Skipper_Jump_Rope.png"
                      alt=""
                      className="mr-4 d-md-none d-sm-inline-block"
                    />
                  </picture>
                  <div>
                    <p>
                      <strong>
                        Not sure what your chronic ITP treatment goals are?
                      </strong>
                    </p>
                    <p>
                      Show up to your next doctor’s appointment prepared with
                      questions like these:
                    </p>

                    <Row>
                      <Col>
                        <div className="d-flex align-items-center mt-3">
                          <img
                            src="/images/icon_question_mark.svg"
                            alt="Question Mark Icon"
                            className="mr-4 d-none d-md-block question-mark"
                          />
                          <div>
                            <p className="d-flex align-items-center">
                              <img
                                src="/images/icon_question_mark.svg"
                                alt="Question Mark Icon"
                                className="mr-4 d-md-none d-sm-inline-block question-mark"
                              />
                              <strong>What is my platelet count goal?</strong>
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className="d-flex align-items-center mt-3">
                          <img
                            src="/images/icon_question_mark.svg"
                            alt="Question Mark Icon"
                            className="mr-4 d-none d-md-block question-mark"
                          />
                          <div>
                            <p className="d-flex align-items-center">
                              <img
                                src="/images/icon_question_mark.svg"
                                alt="Question Mark Icon"
                                className="mr-4 d-md-none d-sm-inline-block question-mark"
                              />
                              <strong>
                                Am I on the right path to reach my platelet
                                goal?
                              </strong>
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className="d-flex align-items-center mt-3">
                          <img
                            src="/images/icon_question_mark.svg"
                            alt="Question Mark Icon"
                            className="mr-4 d-none d-md-block question-mark"
                          />
                          <div>
                            <p className="d-flex align-items-center">
                              <img
                                src="/images/icon_question_mark.svg"
                                alt="Question Mark Icon"
                                className="mr-4 d-md-none d-sm-inline-block question-mark"
                              />
                              <strong>
                                How can Doptelet help me lift my platelet count?
                              </strong>
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <picture>
                    <source
                      media="(min-width: 992px)"
                      srcSet="/images/platey_Skipper_Jump_Rope_D.png 1x, /images/platey_Skipper_Jump_Rope_@2X_D.png 2x"
                      type="image/png"
                    />
                    <source
                      media="(max-width: 991px)"
                      srcSet="/images/platey_Skipper_Jump_Rope.png 1x, /images/platey_Skipper_Jump_Rope_@2X.png 2x"
                      type="image/png"
                    />
                    <img
                      src="/images/platey_Skipper_Jump_Rope.png"
                      alt="Doptelet Platelet Character Jumping Rope"
                      className="mr-4 d-none d-md-block"
                    />
                  </picture>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <div className="cloud-blue-bg video-section">
          <Container>
            <Row className="align-items-center">
              <Col lg={{ span: 4, offset: 1 }}>
                <h2 className="d-lg-none mb-4">
                  Tammy’s Tips:
                  <br />
                  Getting the Most Out of Your Doctor’s Appointments
                </h2>
                <div className="video-wrapper">
                  <YoutubeEmbed
                    embedId="Pm7emPcFX08"
                    image={[
                      "tammys_tips_getting_the_most.png",
                      "tammys_tips_getting_the_most_@2X.png",
                    ]}
                    imageMobile={[
                      "tammys_tips_getting_the_most.png",
                      "tammys_tips_getting_the_most_@2X.png",
                    ]}
                    alt="Play Video: Tammy’s Tips: Getting the Most Out of Your Doctor’s Appointments"
                  />
                </div>
              </Col>
              <Col lg={6}>
                <h2 className="d-lg-block d-none">
                  Tammy’s Tips:
                  <br />
                  Getting the Most Out of Your Doctor’s Appointments
                </h2>
                <p>
                  Dr. Van Doren is a real doctor, but not Tammy’s doctor. Tammy
                  is a real Doptelet patient.
                </p>
              </Col>
            </Row>
          </Container>
        </div>

        <section
          className="not-sure scroll-to-section"
          id="doctor-discussion-guide">
          <Container>
            <Row>
              <Col md={{ span: 10, offset: 1 }}>
                <div className="d-flex align-items-center mb-1 mt-5 question-section">
                  <picture>
                    <source
                      media="(min-width: 992px)"
                      srcSet="/images/platey_Sage_Reading_D.png 1x, /images/platey_Sage_Reading_@2X_D.png 2x"
                      type="image/png"
                    />
                    <source
                      media="(max-width: 991px)"
                      srcSet="/images/platey_Sage_Reading.png 1x, /images/platey_Sage_Reading_@2X.png 2x"
                      type="image/png"
                    />
                    <img
                      src="/images/platey_Sage_Reading.png"
                      alt="Doptelet platelet character reading a book"
                      className="mr-4 d-md-none d-sm-inline-block platey-reads"
                    />
                  </picture>
                  <div>
                    <h2>Treatment takes teamwork</h2>
                    <p className="no-margin-bottom">
                      Working with your doctor can help raise your chances of
                      finding a treatment that fits some of your personal
                      preferences and helps you reach your goals. It’s called
                      shared decision-making, and we have a guide that teaches
                      you how it works.
                    </p>

                    <Row>
                      <Col>
                        <div className="d-flex align-items-center mt-4">
                          <img
                            src="/images/icon_brochure.svg"
                            alt="Doptelet Information Brochure Icon"
                            className="mr-4 d-none d-md-block question-mark"
                          />
                          <div>
                            <p className="d-flex align-items-center">
                              <img
                                src="/images/icon_brochure.svg"
                                alt="Doptelet Information Brochure Icon"
                                className="mr-4 d-md-none d-sm-inline-block question-mark"
                              />
                              <a
                                href="/pdf/Dr-Discussion-Guide.pdf"
                                target="_blank"
                                className="download-link"
                                rel="noopener noreferrer">
                                DOWNLOAD GUIDE
                                <span>
                                  <img
                                    src="/images/icon_download.svg"
                                    alt="Download icon"
                                    aria-hidden="true"
                                  />
                                </span>
                              </a>
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <picture>
                    <source
                      media="(min-width: 992px)"
                      srcSet="/images/platey_Sage_Reading_D.png 1x, /images/platey_Sage_Reading_@2X_D.png 2x"
                      type="image/png"
                    />
                    <source
                      media="(max-width: 991px)"
                      srcSet="/images/platey_Sage_Reading.png 1x, /images/platey_Sage_Reading_@2X.png 2x"
                      type="image/png"
                    />
                    <img
                      src="/images/platey_Sage_Reading.png"
                      alt="Doptelet Platelet Character Reading a Book"
                      className="mr-4 d-none d-md-block platey-reads"
                    />
                  </picture>
                </div>
                <p className="mt-5">
                  <strong>
                    Here are some other resources you may find helpful
                  </strong>
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="not-sure blue-bg pt-5 pb-5">
          <Container>
            <Row>
              <Col md={{ span: 10, offset: 1 }}>
                <div className="d-flex align-items-start mb-1">
                  <img
                    src="/images/icon_brochure.svg"
                    alt="Doptelet Information Brochure Icon"
                    className="mr-4 d-none d-md-block"
                  />
                  <div>
                    <p className="d-flex align-items-center">
                      <img
                        src="/images/icon_brochure.svg"
                        alt="Doptelet Information Brochure Icon"
                        className="mr-4 d-md-none d-sm-inline-block"
                      />
                      <strong>Want to take control of your ITP?</strong>
                    </p>
                    <p>
                      Learn about the app that helps you track your platelet
                      count progress, record chronic immune
                      thrombocytopenia-related events, like bleeds, and more.
                    </p>
                    <Resource.AppLink
                      link="https://apps.apple.com/us/app/my-florio-itp/id1532235504"
                      image="/images/app-store@2x.png"
                      imageAlt="App Store Button"></Resource.AppLink>
                    <Resource.Link link="/pdf/florio-itp-app-one-pager.pdf">
                      Download Brochure
                    </Resource.Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="not-sure pt-5 pb-5">
          <Container>
            <Row>
              <Col md={{ span: 10, offset: 1 }}>
                <div className="d-flex align-items-start mb-1">
                  <img
                    src="/images/icon_brochure.svg"
                    alt="Doptelet Information Brochure Icon"
                    className="mr-4 d-none d-md-block"
                  />
                  <div>
                    <p className="d-flex align-items-center">
                      <img
                        src="/images/icon_brochure.svg"
                        alt="Doptelet Information Brochure Icon"
                        className="mr-4 d-md-none d-sm-inline-block"
                      />
                      <strong>Considering Doptelet?</strong>
                    </p>
                    <p>
                      Get a quick overview of Doptelet benefits before talking
                      to your doctor about whether it may be the right fit for
                      you.
                    </p>
                    <a
                      href="/pdf/Patient-Trifold.pdf"
                      target="_blank"
                      className="download-link">
                      DOWNLOAD TRIFOLD
                      <span>
                        <img
                          src="/images/icon_download.svg"
                          alt="Download icon"
                          aria-hidden="true"
                        />
                      </span>
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="not_sure blue-bg pt-5 pb-5">
          <Container>
            <Row>
              <Col md={{ span: 10, offset: 1 }}>
                <div className="d-flex align-items-start mb-1">
                  <img
                    src="/images/icon_brochure.svg"
                    alt="Doptelet Information Brochure Icon"
                    className="mr-4 d-none d-md-block"
                  />
                  <div>
                    <p className="d-flex align-items-center">
                      <img
                        src="/images/icon_brochure.svg"
                        alt="Doptelet Information Brochure Icon"
                        className="mr-4 d-md-none d-sm-inline-block"
                      />
                      <strong>Starting Doptelet?</strong>
                    </p>
                    <p>
                      Read more about chronic ITP and its treatment with
                      Doptelet after talking to your doctor.
                    </p>
                    <a
                      href="/pdf/Patient-Brochure.pdf"
                      target="_blank"
                      className="download-link">
                      DOWNLOAD BROCHURE
                      <span>
                        <img
                          src="/images/icon_download.svg"
                          alt="Download icon"
                          aria-hidden="true"
                        />
                      </span>
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="not-sure pt-5 pb-5">
          <Container>
            <Row>
              <Col md={{ span: 10, offset: 1 }}>
                <div className="d-flex align-items-center mb-1 question-section">
                  <picture>
                    <source
                      srcSet="/images/platey_Free_Hugs.png 1x, /images/platey_Free_Hugs_@2X.png 2x"
                      type="image/png"
                    />
                    <img
                      src="/images/platey_Free_Hugs.png"
                      alt="Doptelet Platelet Character Holding 'Free Hugs' Sign"
                      className="mr-4 d-md-none d-sm-inline-block free-hugs"
                    />
                  </picture>
                  <div>
                    <h4>
                      <strong>Don’t forget about financial support </strong>
                    </h4>
                    <p>
                      Let us help connect you to our financial assistance
                      options around things like copay. Click the button below
                      to find out if you’re eligible for any of them.
                    </p>
                    <div className="text-center">
                      <PrimaryButton
                        title="FINANCIAL ASSISTANCE"
                        href="/financial-assistance-and-support/financial-assistance/"
                      />
                    </div>
                  </div>
                  <picture>
                    <source
                      srcSet="/images/platey_Free_Hugs_Platey_D.png 1x, /images/platey_Free_Hugs_Platey_@2X_D.png 2x"
                      type="image/png"
                    />
                    <img
                      src="/images/platey_Free_Hugs.png"
                      alt="Doptelet Platelet Character Holding 'Free Hugs' Sign"
                      className="mr-4 d-none d-md-block free-hugs"
                    />
                  </picture>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <ISIITPinline />
      </div>
      <FooterITP />
      <ISIwrapper>
        <ISIITP />
      </ISIwrapper>
      <BackToTopBtn />
    </div>
  );
}

export default TalkToYourDoctor;
