import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { helmetJsonLdProp } from "react-schemaorg";

import { Container, Row, Col } from "react-bootstrap/esm/index";

// Import Components
import TextOnlyHeroHeader from "../../components/HeroHeader/TextOnlyHeroHeader";
import ItpNavigation from "../../components/ITPNavigation/ITPNavigation";
import ITPHeroHeader from "../../components/HeroHeader/ITPHeroHeader";
import FooterITP from "../../components/shared-ui/Footer/Footer-ITP";
import BackToTopBtn from "../../components/shared-ui/BackToTopBtn/BackToTopBtn";
import ISIITPinline from "../../components/shared-ui/ISI/ISIITP-inline";
import ISIwrapper from "../../components/shared-ui/ISI/ISI-wrapper";
import ISIITP from "../../components/shared-ui/ISI/ISIITP";
import RecruitmentForm from "../../components/RecruitmentForm/RecruitmentForm";
import PrimaryButton from "../../components/shared-ui/Buttons/PrimaryButton";

// Import Styles
import "../../css/ShareYourStory.scss";

function ShareYourStory() {
  useEffect(() => {
    window.scrollTo(0, 0);
    let location = window.location.pathname;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      page: {
        path: `${location}`,
        title: "Share Your ITP Story | DOPTELET® (avatrombopag)",
        environment: "dev",
        indication: "ITP",
        audience: "DTC",
      },
    });
  }, []);
  return (
    <div className="main_content_wrap">
      <Helmet
        script={[
          helmetJsonLdProp({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "Drug",
                name: "Doptelet",
                alternateName: "avatrombopag",
                manufacturer: {
                  "@type": "Organization",
                  name: "Sobi",
                },
                dosageForm: "Pill",
              },
              {
                "@type": "WebSite",
                about: {
                  "@type": "Thing",
                  description:
                    "To inform patients of Doptelet (avatrombopag), a prescription medicine used to treat low blood platelet counts in adults with chronic immune thrombocytopenia when other treatments have not worked well enough. Doptelet (avatrombopag) is also a prescription medicine used to treat low blood platelet counts in adults with long-lasting (chronic) liver disease who are scheduled to have a medical or dental procedure.",
                },
                url: "https://doptelethcp.com/itp/",
              },
              {
                "@type": "Brand",
                logo: "https://doptelet.com/images/doptelet-avatrombopag-tablets-logo.svg",
              },
              {
                "@type": "Brand",
                logo: "https://doptelet.com/images/sobi-logo.png",
              },
              {
                "@type": "MedicalAudience",
                audienceType:
                  "Patients with chronic immune thrombocytopenia when other treatments have not worked well enough. ",
                healthCondition: {
                  "@type": "MedicalCondition",
                  alternateName: "Immune Thrombocytopenia (ITP)",
                },
                requiredMinAge: "18",
              },
              {
                "@type": "MedicalTherapy",
                seriousAdverseOutcome: {
                  "@type": "MedicalEntity",
                  description:
                    "DOPTELET may cause serious side effects, including blood clots. People with chronic immune thrombocytopenia and people with certain blood clotting conditions may have an increased risk of developing blood clots. Tell your healthcare provider right away if you have signs and symptoms of a blood clot, including:Swelling, pain, or tenderness in your legs, Shortness of breath, Chest pain, Fast heartbeat, and Stomach (abdominal) pain or tenderness.",
                },
              },
              {
                "@type": "MedicalTherapy",
                adverseOutcome: {
                  "@type": "MedicalEntity",
                  description:
                    "'The most common side effects of DOPTELET when used to treat low blood platelet counts in adults with chronic immune thrombocytopenia (ITP) are: headache, tiredness, bruising, nosebleed, upper-respiratory tract infection, joint pain, gum bleeding, and purple or red spots on your skin.",
                },
              },
              {
                "@type": "HealthTopicContent",
                hasHealthAspect:
                  "The most common side effects of DOPTELET when used to treat low blood platelet counts in adults with chronic immune thrombocytopenia (ITP) are: headache, tiredness, bruising, nosebleed, upper-respiratory tract infection, joint pain, gum bleeding, purple or red spots on your skin, runny nose",
              },
              {
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 2,
                    item: {
                      "@id":
                        "https://doptelet.com/support-and-resources/share-your-story/",
                      Name: "ITP",
                    },
                  },
                ],
              },
            ],
          }),
        ]}>
        <title>Share Your Chronic ITP Story | DOPTELET® (avatrombopag)</title>
        <meta
          name="description"
          content="Are you living with Chronic ITP? Join a community of patients with similar experiences and lend your support by sharing your ITP story."
        />
        <link
          rel="canonical"
          href="https://doptelet.com/support-and-resources/share-your-story/"
        />
      </Helmet>
      <ItpNavigation />
      <div className="inner-content-wrap share-your-story">
        <ITPHeroHeader
          extraClass="large-hero inner-bg"
          markup={
            <>
              <h1>Share Your Chronic ITP Story</h1>
              <p>
                Are you living with low platelets in chronic ITP and treating
                them with Doptelet? Sharing your story can help inform and
                inspire others. Sounds awesome, right? Scroll down to start!
              </p>
            </>
          }
          maxWidth="513"
          bgImage={["share-story-hero.jpg", "share-story-hero.jpg"]}
          bgImageMobile={[
            "share-story-hero-mobile.jpg",
            "share-story-hero-mobile.jpg",
          ]}
          imageAlt='Plane flying over plateletville leaving a chemtrail that reads "Doptelet" in the sky'
        />

        <section className="doptelet-share-your-story">
          <Container>
            <Row>
              <Col lg={{ span: 12 }}>
                <div className="form-intro">
                  <h2>What’s your contact information?</h2>
                  <p>
                    If you, or someone you care for, is currently on Doptelet
                    and would like to learn more about sharing a story, fill out
                    the form, and one of our representatives will get in touch.
                  </p>
                </div>
                <div className="recruitment-form">
                  <RecruitmentForm />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <div className="mt-md-5 pt-5 bottom-page-ctas">
          <Container fluid>
            <Row>
              <Col md={6} className="d-flex flex-column">
                <div className="content">
                  <picture className="text-center">
                    <source
                      srcSet="/images/Platey_Ryder_Bubbles.png 1x, /images/Platey_Ryder_Bubbles@2x.png 2x"
                      type="image/png"
                    />
                    <img
                      src="/images/Platey_Ryder_Bubbles.png"
                      alt="Doptelet Platelet Character Blowing Bubbles"
                    />
                  </picture>
                  <h2>Do you want updates on tips for managing chronic ITP?</h2>
                  <p className="mb-4 flex-grow-1">
                    Sign up to access tips and tools for managing
                    ITP&ndash;plus, a surprise shipped to your doorstep!
                  </p>
                  <PrimaryButton
                    title="Get Chronic ITP Tips"
                    href="/support-and-resources/living-with-itp/"
                    addClass="btn-transparent btn-arrow align-self-start"
                  />
                </div>
              </Col>
              <Col md={6} className="cloud-blue-bg d-flex flex-column">
                <div className="content">
                  <picture className="text-center">
                    <source
                      srcSet="/images/have-questions.jpg 1x, /images/have-questions.jpg 2x"
                      type="image/png"
                    />
                    <img
                      src="/images/have-questions.jpg"
                      alt="Doptelet platelet character holding smartphone"
                    />
                  </picture>
                  <h2>Have more questions?</h2>
                  <p className="flex-grow-1">
                    Answers to your questions about Doptelet are just a click
                    away!
                  </p>
                  <PrimaryButton
                    title="View FAQ"
                    href="/chronic-itp-faqs/"
                    addClass="btn-arrow align-self-start"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <ISIITPinline />
      </div>
      <FooterITP />
      <ISIwrapper>
        <ISIITP />
      </ISIwrapper>
      <BackToTopBtn />
    </div>
  );
}

export default ShareYourStory;
