import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { helmetJsonLdProp } from "react-schemaorg";

import { Container, Col, Row } from "react-bootstrap/esm/index";

// Import Components
import HalfTextHalfImageHero from "../../components/HeroHeader/HalfTextHalfImageHero";
import ITPHeroHeader from "../../components/HeroHeader/ITPHeroHeader";
import PrimaryButton from "../../components/shared-ui/Buttons/PrimaryButton";
import ItpNavigation from "../../components/ITPNavigation/ITPNavigation";
import FooterITP from "../../components/shared-ui/Footer/Footer-ITP";
import BackToTopBtn from "../../components/shared-ui/BackToTopBtn/BackToTopBtn";
import ISIITPinline from "../../components/shared-ui/ISI/ISIITP-inline";
import ISIwrapper from "../../components/shared-ui/ISI/ISI-wrapper";
import ISIITP from "../../components/shared-ui/ISI/ISIITP";
import YoutubeEmbed from "../../components/shared-ui/Video/YoutubeEmbed";

// Import Styles
import "../../css/ITPHome.scss";
import "../../css/Cards.scss";
import "../../css/VideoSection.scss";

function showPhreesiaModalHome() {
  let modal = document.querySelector("#phreesia-modal");
  document.body.classList.add("no-scroll");
  modal.classList.remove("fadeOut");
  modal.style.display = "block";
  modal.classList.add("fadeIn");
  modal.style.height = "100%";
  modal.querySelector(".exitBtn").addEventListener("click", function () {
    modal.style.display = "none";
  });
}

function ITPHome() {
  useEffect(() => {
    window.scrollTo(0, 0);
    let location = window.location.pathname;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      page: {
        path: `${location}`,
        title: "Immune Thrombocytopenia (ITP) | DOPTELET® (avatrombopag)",
        environment: "dev",
        indication: "ITP",
        audience: "DTC",
      },
    });
  }, []);
  return (
    <div className="main_content_wrap">
      <Helmet
        script={[
          helmetJsonLdProp({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "Drug",
                name: "Doptelet",
                alternateName: "avatrombopag",
                manufacturer: {
                  "@type": "Organization",
                  name: "Sobi",
                },
                dosageForm: "Pill",
              },
              {
                "@type": "WebSite",
                about: {
                  "@type": "Thing",
                  description:
                    "To inform patients of Doptelet (avatrombopag), a prescription medicine used to treat low blood platelet counts in adults with chronic immune thrombocytopenia when other treatments have not worked well enough. Doptelet (avatrombopag) is also a prescription medicine used to treat low blood platelet counts in adults with long-lasting (chronic) liver disease who are scheduled to have a medical or dental procedure.",
                },
                url: "https://doptelethcp.com/itp/",
              },
              {
                "@type": "Brand",
                logo: "https://doptelet.com/images/doptelet-avatrombopag-tablets-logo.svg",
              },
              {
                "@type": "Brand",
                logo: "https://doptelet.com/images/sobi-logo.png",
              },
              {
                "@type": "MedicalAudience",
                audienceType:
                  "Patients with chronic immune thrombocytopenia when other treatments have not worked well enough. ",
                healthCondition: {
                  "@type": "MedicalCondition",
                  alternateName: "Immune Thrombocytopenia (ITP)",
                },
                requiredMinAge: "18",
              },
              {
                "@type": "MedicalTherapy",
                seriousAdverseOutcome: {
                  "@type": "MedicalEntity",
                  description:
                    "DOPTELET may cause serious side effects, including blood clots. People with chronic immune thrombocytopenia and people with certain blood clotting conditions may have an increased risk of developing blood clots. Tell your healthcare provider right away if you have signs and symptoms of a blood clot, including:Swelling, pain, or tenderness in your legs, Shortness of breath, Chest pain, Fast heartbeat, and Stomach (abdominal) pain or tenderness.",
                },
              },
              {
                "@type": "MedicalTherapy",
                adverseOutcome: {
                  "@type": "MedicalEntity",
                  description:
                    "'The most common side effects of DOPTELET when used to treat low blood platelet counts in adults with chronic immune thrombocytopenia (ITP) are: headache, tiredness, bruising, nosebleed, upper-respiratory tract infection, joint pain, gum bleeding, and purple or red spots on your skin.",
                },
              },
              {
                "@type": "HealthTopicContent",
                hasHealthAspect:
                  "The most common side effects of DOPTELET when used to treat low blood platelet counts in adults with chronic immune thrombocytopenia (ITP) are: headache, tiredness, bruising, nosebleed, upper-respiratory tract infection, joint pain, gum bleeding, purple or red spots on your skin, runny nose",
              },
              {
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 2,
                    item: {
                      "@id": "https://doptelet.com/",
                      Name: "ITP",
                    },
                  },
                ],
              },
            ],
          }),
        ]}>
        <title>
          Chronic Immune Thrombocytopenia (ITP) Treatment | DOPTELET®
          (avatrombopag)
        </title>
        <meta
          name="description"
          content="Discover DOPTELET® (avatrombopag), a medication used to increase low blood platelet counts in adults with chronic immune thrombocytopenia (ITP)."
        />
        <link rel="canonical" href="https://doptelet.com/home/" />
      </Helmet>
      <ItpNavigation />
      <div className="inner-content-wrap itpHome">
        <ITPHeroHeader
          extraClass="large-hero inner-bg"
          textSitsOnImageForMobile
          markup={
            <>
              <h1>Not all chronic ITP treatments are created equal</h1>
              <p>
                Doptelet is the only oral chronic ITP medication to increase
                platelets to 50,000 per microliter that doesn’t restrict when
                you can eat dairy or iron-rich foods. Unlike other treatment
                options, food doesn’t get in the way of how well your medicine
                works.
              </p>
              <p className="mb-0">
                <a
                  className="btn btn-primary white-border"
                  href="/what-is-doptelet/">
                  STEP RIGHT UP
                </a>
              </p>
            </>
          }
          maxWidth="525"
          bgImage={["PlateletCarnival_D.png", "PlateletCarnival_D.png"]}
          bgImageMobile={["home-hero.jpg", "home-hero@2x.jpg"]}
        />

        <Container>
          <Row className="d-block d-md-none mt-4">
            <Col>
              <p className="size-16 mb-0">
                Friendly reminder: Food’s a must when taking your dose of
                Doptelet, but which kind is up to you.
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={{ span: 12 }}>
              <h2 className="mb-5 mt-5">
                Which of these sounds most like you?
              </h2>
            </Col>
          </Row>
          <Row className="justify-content-between home-ctas">
            <Col className="d-flex flex-column mb-5" xl={12} lg={4}>
              <div className="d-flex flex-row align-items-center">
                <picture>
                  <img
                    src="/images/Platey_Sage_Waving.png"
                    alt="Doptelet Platelet Character Waving"
                  />
                </picture>
                <h4>
                  <strong>I’m just learning about chronic ITP</strong>
                </h4>
              </div>
              <p className="flex-grow-1">
                Find out what ITP is and what symptoms come with it.
              </p>
              <PrimaryButton
                title="WHAT IS ITP?"
                href="/what-is-itp/"
                addClass="btn-transparent btn-arrow"
              />
            </Col>
            <Col
              className="d-flex flex-column mb-5 this-doesnt-follow-the-grid"
              xl={12}
              lg={4}>
              <div className="d-flex flex-row align-items-center">
                <picture>
                  <source
                    srcSet="/images/Platey_Other_Waving.png 1x, /images/Platey_Other_Waving@2x.png 2x"
                    type="image/png"
                  />
                  <img
                    src="/images/Platey_Other_Waving.png"
                    alt="Doptelet Platelet Character Waving"
                  />
                </picture>
                <h4 className="pr-lg-5">
                  <strong>I’m looking to manage my chronic ITP</strong>
                </h4>
              </div>
              <p className="flex-grow-1">
                Learn how to manage ITP with your preferences in mind.
              </p>
              <PrimaryButton
                title="MANAGING ITP"
                href="/managing-itp/"
                addClass="btn-transparent btn-arrow"
              />
            </Col>
            <Col className="d-flex flex-column mb-5" xl={12} lg={4}>
              <div className="d-flex flex-row align-items-center">
                <picture>
                  <img
                    src="/images/Platey_Tossing_Kid.png"
                    alt="Doptelet Platelet Character Wearing a Hat"
                  />
                </picture>
                <h4>
                  <strong>I’m already on Doptelet</strong>
                </h4>
              </div>
              <p className="flex-grow-1">
                See if you’re eligible for any financial assistance programs.
              </p>
              <PrimaryButton
                title="FINANCIAL ASSISTANCE INFO"
                href="/support-and-resources/financial-assistance/"
                addClass="btn-transparent btn-arrow"
              />
            </Col>
          </Row>
          <Row className="d-none d-md-block">
            <Col lg={10} xl={{ span: 12 }}>
              <p className="size-16">
                Friendly reminder: Food’s a must when taking your dose of
                Doptelet, but which kind is up to you.
              </p>
            </Col>
          </Row>
        </Container>

        <div className="mt-0 mt-md-5 pt-2 cloud-blue-bg dopteller-diaries video-section">
          <Container>
            <Row className="">
              <Col lg={{ span: 6 }}>
                <h2>Dopteller Diaries: Life After Diagnosis</h2>
                <div className="video-wrapper d-lg-none">
                  <YoutubeEmbed
                    embedId="BuIhQABk2-A"
                    image={[
                      "dopdiaries-life-after-diagnosis.jpg",
                      "dopdiaries-life-after-diagnosis.jpg",
                    ]}
                    imageMobile={[
                      "dopdiaries-life-after-diagnosis.jpg",
                      "dopdiaries-life-after-diagnosis.jpg",
                    ]}
                    alt="Play Video: Dopteller Diaries: Life After Diagnosis"
                  />
                </div>

                <p>
                  Hear how Tammy, a real patient on Doptelet, was able to change
                  her mindset after being diagnosed with chronic ITP.
                </p>
                <p>If you are on Doptelet too, join Tammy as an ambassador!</p>
                <div className="d-flex flex-column flex-md-row">
                  <PrimaryButton
                    title="GET TAMMY'S TIPS"
                    href="/support-and-resources/chronic-itp-videos/"
                    addClass="btn-transparent btn-arrow mb-md-0 mb-3 mr-md-3"
                  />
                  <PrimaryButton
                    title="SHARE YOUR STORY"
                    href="/support-and-resources/share-your-story/"
                    addClass="btn-transparent btn-arrow"
                  />
                </div>
              </Col>
              <Col lg={6} className="d-none d-lg-block">
                <div className="video-wrapper">
                  <YoutubeEmbed
                    embedId="BuIhQABk2-A"
                    image={[
                      "dopdiaries-life-after-diagnosis.jpg",
                      "dopdiaries-life-after-diagnosis.jpg",
                    ]}
                    imageMobile={[
                      "dopdiaries-life-after-diagnosis.jpg",
                      "dopdiaries-life-after-diagnosis.jpg",
                    ]}
                    alt="Play Video: Dopteller Diaries: Life After Diagnosis"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <section className="pt-5 pb-5">
          <Container>
            <Row>
              <Col md={{ span: 12 }}>
                <div className="d-flex align-items-center flex-column flex-lg-row mb-1 ">
                  <picture>
                    <source
                      srcSet="/images/Platey_Ryder_Bubbles.png 1x"
                      type="image/png"
                    />
                    <img
                      src="/images/Platey_Ryder_Bubbles.png"
                      alt="Doptelet Platelet Character Blowing Bubbles"
                      className="mr-4 d-lg-none d-sm-inline-block free-hugs"
                    />
                  </picture>
                  <div>
                    <h2>
                      Do you want updates on tips for managing chronic ITP?
                    </h2>
                    <p>
                      Sign up to access tips and tools for managing chronic ITP
                      <br className="d-none d-lg-block" />
                      —plus, a surprise shipped to your doorstep!
                    </p>
                    <PrimaryButton
                      title="GET CHRONIC ITP TIPS"
                      href="/support-and-resources/living-with-itp/"
                      addClass="btn-arrow"
                    />
                  </div>
                  <picture>
                    <img
                      src="/images/Platey_Ryder_Bubbles.png"
                      alt="Doptelet Platelet Character Blowing Bubbles"
                      className="mr-4 d-none d-lg-block"
                    />
                  </picture>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <ISIITPinline />
      </div>
      <FooterITP />
      <ISIwrapper>
        <ISIITP />
      </ISIwrapper>
      <BackToTopBtn />
    </div>
  );
}

export default ITPHome;
