import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { helmetJsonLdProp } from "react-schemaorg";

import { Container, Row, Col } from "react-bootstrap/esm/index";

// Import Components
import TextOnlyHeroHeader from "../../components/HeroHeader/TextOnlyHeroHeader";
import PrimaryButton from "../../components/shared-ui/Buttons/PrimaryButton";
import ItpNavigation from "../../components/ITPNavigation/ITPNavigation";
import FooterITP from "../../components/shared-ui/Footer/Footer-ITP";
import BackToTopBtn from "../../components/shared-ui/BackToTopBtn/BackToTopBtn";
import ISIITPinline from "../../components/shared-ui/ISI/ISIITP-inline";
import ISIwrapper from "../../components/shared-ui/ISI/ISI-wrapper";
import ISIITP from "../../components/shared-ui/ISI/ISIITP";
import YoutubeEmbed from "../../components/shared-ui/Video/YoutubeEmbed";

// Import Styles
import "../../css/HearFromARealPatient.scss";
import "../../css/VideoSection.scss";

function HearFromARealPatient() {
  useEffect(() => {
    window.scrollTo(0, 0);
    let location = window.location.pathname;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      page: {
        path: `${location}`,
        title: "Real ITP Patient Stories | DOPTELET® (avatrombopag)",
        environment: "dev",
        indication: "ITP",
        audience: "DTC",
      },
    });
  }, []);
  return (
    <div className="main_content_wrap">
      <Helmet
        script={[
          helmetJsonLdProp({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "Drug",
                name: "Doptelet",
                alternateName: "avatrombopag",
                manufacturer: {
                  "@type": "Organization",
                  name: "Sobi",
                },
                dosageForm: "Pill",
              },
              {
                "@type": "WebSite",
                about: {
                  "@type": "Thing",
                  description:
                    "To inform patients of Doptelet (avatrombopag), a prescription medicine used to treat low blood platelet counts in adults with chronic immune thrombocytopenia when other treatments have not worked well enough. Doptelet (avatrombopag) is also a prescription medicine used to treat low blood platelet counts in adults with long-lasting (chronic) liver disease who are scheduled to have a medical or dental procedure.",
                },
                url: "https://doptelethcp.com/itp/",
              },
              {
                "@type": "Brand",
                logo: "https://doptelet.com/images/doptelet-avatrombopag-tablets-logo.svg",
              },
              {
                "@type": "Brand",
                logo: "https://doptelet.com/images/sobi-logo.png",
              },
              {
                "@type": "MedicalAudience",
                audienceType:
                  "Patients with chronic immune thrombocytopenia when other treatments have not worked well enough. ",
                healthCondition: {
                  "@type": "MedicalCondition",
                  alternateName: "Immune Thrombocytopenia (ITP)",
                },
                requiredMinAge: "18",
              },
              {
                "@type": "MedicalTherapy",
                seriousAdverseOutcome: {
                  "@type": "MedicalEntity",
                  description:
                    "DOPTELET may cause serious side effects, including blood clots. People with chronic immune thrombocytopenia and people with certain blood clotting conditions may have an increased risk of developing blood clots. Tell your healthcare provider right away if you have signs and symptoms of a blood clot, including:Swelling, pain, or tenderness in your legs, Shortness of breath, Chest pain, Fast heartbeat, and Stomach (abdominal) pain or tenderness.",
                },
              },
              {
                "@type": "MedicalTherapy",
                adverseOutcome: {
                  "@type": "MedicalEntity",
                  description:
                    "'The most common side effects of DOPTELET when used to treat low blood platelet counts in adults with chronic immune thrombocytopenia (ITP) are: headache, tiredness, bruising, nosebleed, upper-respiratory tract infection, joint pain, gum bleeding, and purple or red spots on your skin.",
                },
              },
              {
                "@type": "HealthTopicContent",
                hasHealthAspect:
                  "The most common side effects of DOPTELET when used to treat low blood platelet counts in adults with chronic immune thrombocytopenia (ITP) are: headache, tiredness, bruising, nosebleed, upper-respiratory tract infection, joint pain, gum bleeding, purple or red spots on your skin, runny nose",
              },
              {
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 2,
                    item: {
                      "@id":
                        "https://doptelet.com/financial-assistance-and-support/itp-patient-stories/",
                      Name: "ITP",
                    },
                  },
                ],
              },
            ],
          }),
        ]}>
        <title>Real ITP Patient Stories | DOPTELET® (avatrombopag)</title>
        <meta
          name="description"
          content="Hear from Tammy, a real Doptelet® ITP patient who talks about life after her ITP diagnosis, general ITP and doctor discussion tips, and more."
        />
        <link
          rel="canonical"
          href="https://doptelet.com/financial-assistance-and-support/itp-patient-stories/"
        />
      </Helmet>
      <ItpNavigation />
      <div className="inner-content-wrap real-patient-stories">
        <TextOnlyHeroHeader
          markup={
            <>
              <h1>Hear From a Real Patient</h1>
              <p>
                Have you met Tammy, our Dopteller? Watch her talk about life
                after diagnosis, how to get the most out of your doctor’s
                appointments, things she wishes she knew when she was diagnosed
                with chronic ITP, and more.
              </p>
              <p>
                P.S., Looking to share your own story? We just so happen to be
                searching for others. Scroll down for all the details.
              </p>
            </>
          }
        />

        <div className="video-section">
          <Container>
            <Row className="align-items-center">
              <Col lg={{ span: 4, offset: 1 }}>
                <h3 className="d-lg-none mb-4">
                  <strong>
                    Dopteller Diaries:
                    <br /> Life After Diagnosis
                  </strong>
                </h3>
                <div className="video-wrapper">
                  <YoutubeEmbed
                    embedId="ymY55nKFDrQ"
                    image={[
                      "dopteller_diaries_life_after_D.png",
                      "dopteller_diaries_life_after_@2X_D.png",
                    ]}
                    imageMobile={[
                      "dopteller_diaries_life_after.png",
                      "dopteller_diaries_life_after_@2X.png",
                    ]}
                    alt="Play Video: Dopteller Diaries: Life After Diagnosis"
                  />
                </div>
              </Col>
              <Col lg={6}>
                <h3 className="d-lg-block d-none">
                  <strong>
                    Dopteller Diaries:
                    <br /> Life After Diagnosis
                  </strong>
                </h3>
                <p>Tammy is a real Doptelet patient.</p>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="cloud-blue-bg video-section">
          <Container>
            <Row className="align-items-center">
              <Col lg={{ span: 4, offset: 1 }}>
                <h3 className="d-lg-none mb-4">
                  <strong>
                    Dopteller Diaries:
                    <br /> Support Resources for ITP
                  </strong>
                </h3>
                <div className="video-wrapper">
                  <YoutubeEmbed
                    embedId="5_fJS5sDBfI"
                    image={[
                      "dopteller_diaries_support_resources_D.png",
                      "dopteller_diaries_support_resources_@2X_D.png",
                    ]}
                    imageMobile={[
                      "dopteller_diaries_support_resources.png",
                      "dopteller_diaries_support_resources_@2X.png",
                    ]}
                    alt="Play Video: Dopteller Diaries: Support Resources for ITP"
                  />
                </div>
              </Col>
              <Col lg={6}>
                <h3 className="d-lg-block d-none">
                  <strong>
                    Dopteller Diaries:
                    <br /> Support Resources for ITP
                  </strong>
                </h3>
                <p>Tammy is a real Doptelet patient.</p>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="video-section">
          <Container>
            <Row className="align-items-center">
              <Col lg={{ span: 4, offset: 1 }}>
                <h3 className="d-lg-none mb-4">
                  <strong>
                    Dopteller Diaries:
                    <br />A Doctor’s Guide to Exploring Treatment Options
                  </strong>
                </h3>
                <div className="video-wrapper">
                  <YoutubeEmbed
                    embedId="0MBBZAyO_To"
                    image={[
                      "tammys_tips_doctors_guide_D.png",
                      "tammys_tips_doctors_guide_@2X_D.png",
                    ]}
                    imageMobile={[
                      "tammys_tips_doctors_guide.png",
                      "tammys_tips_doctors_guide_@2X.png",
                    ]}
                    alt="Play Video: Tammy’s Tips: A Doctor’s Guide to Exploring Treatment Options"
                  />
                </div>
              </Col>
              <Col lg={6}>
                <h3 className="d-lg-block d-none">
                  <strong>
                    Dopteller Diaries:
                    <br />A Doctor’s Guide to Exploring Treatment Options
                  </strong>
                </h3>
                <p>Dr. Van Doren is a real doctor, but not Tammy’s doctor.</p>
              </Col>
            </Row>
          </Container>
        </div>

        <div
          className="cloud-blue-bg video-section scroll-to-section"
          id="important-questions">
          <Container>
            <Row className="align-items-center">
              <Col lg={{ span: 4, offset: 1 }}>
                <h3 className="d-lg-none mb-4">
                  <strong>
                    Tammy’s Tips:
                    <br /> Important Questions to Ask Your Doctor About Setting
                    Treatment Goals
                  </strong>
                </h3>
                <div className="video-wrapper">
                  <YoutubeEmbed
                    embedId="BuIhQABk2-A"
                    image={[
                      "tammys_tips_important_questions_D.png",
                      "tammys_tips_important_questions_@2X_D.png",
                    ]}
                    imageMobile={[
                      "tammys_tips_important_questions.png",
                      "tammys_tips_important_questions_@2X.png",
                    ]}
                    alt="Play Video: Tammy’s Tips: Important Questions to Ask Your Doctor About Setting Treatment Goals"
                  />
                </div>
              </Col>
              <Col lg={6}>
                <h3 className="d-lg-block d-none">
                  <strong>
                    Tammy’s Tips:
                    <br /> Important Questions to Ask Your Doctor About Setting
                    Treatment Goals
                  </strong>
                </h3>
                <p>Tammy is a real Doptelet patient.</p>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="video-section">
          <Container>
            <Row className="align-items-center">
              <Col lg={{ span: 4, offset: 1 }}>
                <h3 className="d-lg-none mb-4">
                  <strong>
                    Tammy’s Tips:
                    <br /> Getting the Most Out of Your Doctor’s Appointments
                  </strong>
                </h3>
                <div className="video-wrapper">
                  <YoutubeEmbed
                    embedId="Pm7emPcFX08"
                    image={[
                      "tammys_tips_getting_the_most_D.png",
                      "tammys_tips_getting_the_most_@2X_D.png",
                    ]}
                    imageMobile={[
                      "tammys_tips_getting_the_most.png",
                      "tammys_tips_getting_the_most_@2X.png",
                    ]}
                    alt="Play Video: Tammy’s Tips: Getting the Most Out of Your Doctor’s Appointments"
                  />
                </div>
              </Col>
              <Col lg={6}>
                <h3 className="d-lg-block d-none">
                  <strong>
                    Tammy’s Tips:
                    <br /> Getting the Most Out of Your Doctor’s Appointments
                  </strong>
                </h3>
                <p>
                  Dr. Van Doren is a real doctor, but not Tammy’s doctor. Tammy
                  is a real Doptelet patient.
                </p>
              </Col>
            </Row>
          </Container>
        </div>

        <div
          className="cloud-blue-bg video-section scroll-to-section"
          id="things-i-wish-i-knew">
          <Container>
            <Row className="align-items-center">
              <Col lg={{ span: 4, offset: 1 }}>
                <h3 className="d-lg-none mb-4">
                  <strong>
                    Tammy’s Tips:
                    <br /> Things I Wish I Knew When I Was Diagnosed with
                    Chronic ITP
                  </strong>
                </h3>
                <div className="video-wrapper">
                  <YoutubeEmbed
                    embedId="zKwNORop9Tw"
                    image={[
                      "tammys_tips_things_I_wish_D.png",
                      "tammys_tips_things_I_wish_@2X_D.png",
                    ]}
                    imageMobile={[
                      "tammys_tips_things_I_wish.png",
                      "tammys_tips_things_I_wish_@2X.png",
                    ]}
                    alt="Play Video: Tammy’s Tips: Things I Wish I Knew When I Was Diagnosed with Chronic ITP"
                  />
                </div>
              </Col>
              <Col lg={6}>
                <h3 className="d-lg-block d-none">
                  <strong>
                    Tammy’s Tips:
                    <br /> Things I Wish I Knew When I Was Diagnosed with
                    Chronic ITP
                  </strong>
                </h3>
                <p>Tammy is a real Doptelet patient.</p>
              </Col>
            </Row>
          </Container>
        </div>

        <section className="platey-mom">
          <Container>
            <Col md={{ span: 10, offset: 1 }}>
              <div className="d-flex align-items-center mt-5 mb-1">
                <img
                  src="/images/platey_Pearl_Mom.svg"
                  alt="Doptelet Platelet Character in Dress with Bow"
                  className="d-none d-md-block"
                />
                <div>
                  <img
                    src="/images/platey_Pearl_Mom.svg"
                    alt="Doptelet Platelet Character in Dress with Bow"
                    className="d-md-none d-sm-inline-block"
                  />
                  <h4>
                    <strong>Want to share your own story?</strong>
                  </h4>
                  <p className="no-margin-bottom">
                    We’re searching for our next ambassador to inform and
                    inspire others living with chronic immune thrombocytopenia
                    by sharing their experiences on Doptelet.
                  </p>
                </div>
              </div>
              <p className="text-center mb-5 mt-4">
                <PrimaryButton
                  title="SHARE YOUR STORY"
                  href="/financial-assistance-and-support/itp-patient-stories/share-your-story/"
                />
              </p>
            </Col>
          </Container>
        </section>

        <ISIITPinline />
      </div>
      <FooterITP />
      <ISIwrapper>
        <ISIITP />
      </ISIwrapper>
      <BackToTopBtn />
    </div>
  );
}

export default HearFromARealPatient;
