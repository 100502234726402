import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap/esm/index";
import Helmet from "react-helmet";
import { helmetJsonLdProp } from "react-schemaorg";

// Import Components
import ITPHeroHeader from "../../components/HeroHeader/ITPHeroHeader";
import Accordion from "../../components/shared-ui/Accordion/Accordion";
import ItpNavigation from "../../components/ITPNavigation/ITPNavigation";
import FooterITP from "../../components/shared-ui/Footer/Footer-ITP";
import BackToTopBtn from "../../components/shared-ui/BackToTopBtn/BackToTopBtn";
import ISIITPinline from "../../components/shared-ui/ISI/ISIITP-inline";
import ISIwrapper from "../../components/shared-ui/ISI/ISI-wrapper";
import ISIITP from "../../components/shared-ui/ISI/ISIITP";
import YoutubeEmbed from "../../components/shared-ui/Video/YoutubeEmbed";
// Import Styles
import "../../css/WhatIsITP.scss";
import PrimaryButton from "../../components/shared-ui/Buttons/PrimaryButton";

function WhatIsITP() {
  useEffect(() => {
    window.scrollTo(0, 0);
    let location = window.location.pathname;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      page: {
        path: `${location}`,
        title:
          "What is Immune Thrombocytopenia (ITP) | DOPTELET® (avatrombopag)",
        environment: "dev",
        indication: "ITP",
        audience: "DTC",
      },
    });
  }, []);

  return (
    <div className="main_content_wrap">
      <Helmet
        script={[
          helmetJsonLdProp({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "MedicalWebPage",
                name: "What is ITP?",
                description:
                  "Learn about immune thrombocytopenia (ITP), a condition characterized by low platelet count and its symptoms, causes, diagnosis, and treatment options.",
                url: "https://doptelet.com/what-is-itp/",
                mainEntity: {
                  "@type": "MedicalCondition",
                  name: "Immune Thrombocytopenia (ITP)",
                  alternateName: "Idiopathic Thrombocytopenic Purpura",
                  description:
                    "Immune thrombocytopenia (ITP) is a condition characterized by low platelet count due to the immune system mistakenly attacking and destroying platelets.",
                  associatedAnatomy: {
                    "@type": "AnatomicalStructure",
                    name: "Blood",
                    description:
                      "The blood plays a crucial role in carrying oxygen, nutrients, and platelets throughout the body.",
                  },
                  possibleTreatment: {
                    "@type": "MedicalTherapy",
                    name: "Treatment options for ITP",
                    description:
                      "Treatment options for ITP include corticosteroids, immune globulins, splenectomy, and thrombopoietin receptor agonists like Doptelet.",
                  },
                },
              },
              {
                "@type": "Drug",
                url: "https://doptelet.com/what-is-doptelet/",
                name: "Doptelet",
                description:
                  "Doptelet (avatrombopag) is a medication approved for the treatment of chronic immune thrombocytopenia (ITP) in certain adult patients.",
                drugClass: "Thrombopoietin Receptor Agonist",
                mechanismOfAction:
                  "Doptelet interacts with the thrombopoietin receptor to stimulate the production and differentiation of megakaryocytes, leading to increased platelet production.",
              },
              {
                "@type": "WebSite",
                about: {
                  "@type": "Thing",
                  description:
                    "To inform patients of Doptelet (avatrombopag), a prescription medicine used to treat low blood platelet counts in adults with chronic immune thrombocytopenia when other treatments have not worked well enough. Doptelet (avatrombopag) is also a prescription medicine used to treat low blood platelet counts in adults with long-lasting (chronic) liver disease who are scheduled to have a medical or dental procedure.",
                },
                url: "https://doptelethcp.com/what-is-itp/",
              },
              {
                "@type": "Brand",
                logo: "https://doptelet.com/images/doptelet-avatrombopag-tablets-logo.svg",
              },
              {
                "@type": "Brand",
                logo: "https://doptelet.com/images/sobi-logo.png",
              },
              {
                "@type": "MedicalSignOrSymptom",
                url: "https://doptelet.com/what-is-itp/",
                name: "Easy Bruising",
                description:
                  "Easy bruising is a common symptom of immune thrombocytopenia (ITP), where even minor injuries can result in large bruises due to low platelet count.",
              },
              {
                "@type": "MedicalIndication",
                url: "https://doptelet.com/what-is-itp/",
                name: "Chronic Immune Thrombocytopenia (ITP)",
                description:
                  "Doptelet is indicated for the treatment of thrombocytopenia in adult patients with chronic immune thrombocytopenia (ITP) who have had an insufficient response to previous treatment.",
              },
              {
                "@type": "MedicalTherapy",
                url: "https://doptelet.com/what-is-itp/",
                name: "Thrombopoietin (TPO) Receptor Agonists in ITP Treatment",
                description:
                  "Thrombopoietin receptor agonists (TPO-RAs), like Doptelet, are used as a medical therapy for the treatment of immune thrombocytopenia (ITP) in certain adult patients. It helps to stimulate platelet production and raise platelet counts in the blood.",
                drug: {
                  "@type": "Drug",
                  name: "Doptelet",
                  alternateName: "Avatrombopag",
                },
              },
              {
                "@type": "MedicalCause",
                url: "https://doptelet.com/what-is-itp/",
                name: "Immune Thrombocytopenia",
                description:
                  "Immune thrombocytopenia (ITP) is a condition characterized by low platelet count due to the immune system mistakenly attacking and destroying platelets.",
              },
              {
                "@type": "MedicalGuideline",
                url: "https://doptelet.com/what-is-itp/",
                name: "Chronic Immune Thrombocytopenia (ITP) Treatment Guidelines",
                description:
                  "Explore the treatment guidelines for chronic immune thrombocytopenia (ITP) to better understand the condition and available treatment options.",
                guidelineSubject: {
                  "@type": "MedicalEntity",
                  name: "Chronic immune thrombocytopenia (ITP)",
                  alternateName: "Idiopathic Thrombocytopenic Purpura",
                  description:
                    "Immune thrombocytopenia (ITP), also known as idiopathic thrombocytopenic purpura, is a blood disorder characterized by low platelet count due to the immune system mistakenly attacking and destroying platelets.",
                },
                code: {
                  "@type": "MedicalCode",
                  codeValue: "D69.3",
                  codingSystem: "ICD-10",
                },
                evidenceLevel: "Level B",
                evidenceOrigin: "Clinical trials and expert consensus",
                guidelineDate: "2022-01-01",
              },
            ],
          }),
        ]}>
        <title>
          What is Immune Thrombocytopenia (ITP)? | DOPTELET® (avatrombopag)
        </title>
        <meta
          name="description"
          content="Learn about chronic ITP, a condition that causes a shortage of platelets in your blood. Get information that can help you understand how it may affect you."
        />
        <link rel="canonical" href="https://doptelet.com/what-is-itp/" />
      </Helmet>
      <ItpNavigation />
      <div className="inner-content-wrap">
        <ITPHeroHeader
          extraClass="large-hero inner-bg"
          markup={
            <>
              <h1>What is ITP?</h1>
              <p>
                When you’re living with immune thrombocytopenia (ITP), there’s a
                shortage of platelets in your blood. Platelets are in your blood
                to help slow and stop bleeding.
              </p>
              <p>
                Whether your body is destroying healthy platelets or struggling
                to make as many as you need, your platelet count needs a lift.
                And if your low platelet counts have lasted for over a year, you
                may have what’s called long-term, or “chronic,” ITP.
              </p>
              <p className="mb-0">
                Some people may call ITP “idiopathic thrombocytopenic purpura,”
                but that’s because its cause used to be unknown. Did you know:
                “idiopathic” means “unexplained?”
              </p>
            </>
          }
          maxWidth="513"
          bgImage={["PlateyCarnivalBalloons.png", "PlateyCarnivalBalloons.png"]}
          bgImageMobile={[
            "what-is-itp-hero-mobile.png",
            "what-is-itp-hero-mobile@2x.png",
          ]}
          imageAlt="Doptelet platelet characters holding colorful balloons in carnival scenery"
        />

        <section className="pt-5 pb-5">
          <Container>
            <Row className="mb-0 mb-md-4">
              <Col md={12} lg={6} className="mb-4 mb-md-0">
                <a
                  href="/what-is-itp/itp-signs-and-symptoms/"
                  className="page-cta-link cloud-blue-bg">
                  <div className="d-flex align-items-center">
                    <img
                      src="/images/signs-symptoms-icon.png"
                      alt="Single platelet icon on blue background with red and white dotted border"
                    />
                    <h4 className="text-orange">
                      <strong>SIGNS &amp; SYMPTOMS</strong>
                    </h4>
                  </div>
                  <p>Learn the signs and symptoms that come with ITP.</p>
                  <img
                    src="/images/arrow-icon.svg"
                    className="d-block ml-auto"
                    alt="Arrow pointing right icon"
                  />
                </a>
              </Col>
              <Col md={12} lg={6}>
                <a
                  href="/what-is-itp/itp-diagnosis-and-treatment/"
                  className="page-cta-link cloud-blue-bg">
                  <div className="d-flex align-items-center">
                    <img
                      src="/images/diagnosis-treatment-icon.png"
                      alt="First aid icon on blue background with red and white dotted border"
                    />
                    <h4 className="text-orange">
                      <strong>DIAGNOSIS &amp; TREATMENT</strong>
                    </h4>
                  </div>
                  <p>
                    Discover how the ITP diagnosis and treatment process works.
                  </p>
                  <img
                    src="/images/arrow-icon.svg"
                    className="d-block ml-auto"
                    alt="Arrow pointing right icon"
                  />
                </a>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="pt-5 pb-5 cloud-blue-bg get-updates">
          <Container>
            <Row>
              <Col md={6} className="align-content-center">
                <h2>Get chronic ITP updates</h2>
                <picture>
                  <source
                    srcSet="/images/welcome-kit-image.png"
                    type="image/png"
                  />
                  <img
                    src="/images/welcome-kit-image.png"
                    alt="Doptelet Platelet Character Blowing Bubbles"
                    className="d-lg-none d-block m-auto"
                  />
                </picture>
                <p>
                  Sign up to access tips and tools for managing chronic
                  ITP—plus, a<br className="d-none d-lg-block" /> surprise
                  shipped to your doorstep!
                </p>
                <PrimaryButton
                  title="GET CHRONIC ITP TIPS"
                  href="/support-and-resources/living-with-itp/"
                  addClass="btn-arrow btn-transparent"
                />
              </Col>
              <Col md={6} className="d-none d-lg-block">
                <picture>
                  <source
                    srcSet="/images/welcome-kit-image.png"
                    type="image/png"
                  />
                  <img
                    src="/images/welcome-kit-image.png"
                    alt="Doptelet Platelet Character Blowing Bubbles"
                    className="m-auto d-none d-md-block"
                  />
                </picture>
              </Col>
            </Row>
          </Container>
        </section>

        <div className="pt-5">
          <Container>
            <Row className="align-items-center">
              <Col>
                <div>
                  <h2>General ITP Questions</h2>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="accordions chronic-itp pb-0 pt-3">
          <Container>
            <Row>
              <Col>
                <Accordion
                  title="Can ITP go away on its own?"
                  bodyText="With newly diagnosed immune thrombocytopenia, there is a chance for it to go away within 3 months, but this type of ITP is found more commonly in children."
                />
                <Accordion
                  title="Is ITP rare?"
                  bodyText="Yes, ITP is a rare disease that affects 9.5 out of every 100,000 adults in the US."
                  bodyText2="Globally, ITP is thought to affect more than 200,000 people."
                />
                <Accordion
                  title="Is ITP cancer?"
                  bodyText="No, ITP is an autoimmune condition in which your immune system attacks the platelets in your body."
                />
                <Accordion
                  title="Is ITP hereditary?"
                  bodyText="Patients with ITP don’t normally have a family history of thrombocytopenia (or low platelet counts), but if you do, you should let your doctor know so a possible genetic link can be considered."
                />
                <Accordion
                  title="Is ITP an autoimmune condition?"
                  bodyText="Yes, ITP is an autoimmune condition. When you have immune thrombocytopenia, your own immune system attacks the platelets in your body, leaving your platelet count low."
                  bodyText2="Having a low platelet count can increase your risk of bleeding or bruising. Sometimes, ITP causes other symptoms, like fatigue or petechiae/purpura—a condition that causes red or purple spots to appear on your skin."
                />
                <Accordion
                  title="What are platelets?"
                  bodyText="Platelets are the part of your blood that helps slow and stop bleeding."
                />
              </Col>
            </Row>
          </Container>
        </div>

        <ISIITPinline />
      </div>
      <FooterITP />
      <ISIwrapper>
        <ISIITP />
      </ISIwrapper>
      <BackToTopBtn />
    </div>
  );
}

export default WhatIsITP;
