import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap/esm/index";
import Helmet from "react-helmet";
import { helmetJsonLdProp } from "react-schemaorg";

// Import Components
import ITPHeroHeader from "../../components/HeroHeader/ITPHeroHeader";
import Accordion from "../../components/shared-ui/Accordion/Accordion";
import ItpNavigation from "../../components/ITPNavigation/ITPNavigation";
import FooterITP from "../../components/shared-ui/Footer/Footer-ITP";
import BackToTopBtn from "../../components/shared-ui/BackToTopBtn/BackToTopBtn";
import ISIITPinline from "../../components/shared-ui/ISI/ISIITP-inline";
import ISIwrapper from "../../components/shared-ui/ISI/ISI-wrapper";
import ISIITP from "../../components/shared-ui/ISI/ISIITP";
import YoutubeEmbed from "../../components/shared-ui/Video/YoutubeEmbed";
// Import Styles
import "../../css/WhatIsITP.scss";
import PrimaryButton from "../../components/shared-ui/Buttons/PrimaryButton";

function ManagingITP() {
  useEffect(() => {
    window.scrollTo(0, 0);
    let location = window.location.pathname;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      page: {
        path: `${location}`,
        title: "Managing ITP | DOPTELET® (avatrombopag)",
        environment: "dev",
        indication: "ITP",
        audience: "DTC",
      },
    });
  }, []);

  return (
    <div className="main_content_wrap">
      <Helmet>
        <title>
          Managing Chronic ITP with TPO-RA Medication | DOPTELET® (avatrombopag)
        </title>
        <meta
          name="description"
          content="Living with chronic ITP? Learn about different treatment options and medications, like Doptelet – a thrombopoietin receptor agonist (TPO-RA) that can help."
        />
        <link rel="canonical" href="https://doptelet.com/managing-itp" />
      </Helmet>
      <ItpNavigation />
      <div className="inner-content-wrap">
        <ITPHeroHeader
          extraClass="large-hero inner-bg"
          markup={
            <>
              <h1>
                HAS YOUR EXPERIENCE WITH CHRONIC ITP BEEN A ROLLER COASTER?
              </h1>
              <p className="mb-0">
                Living with low platelet counts in chronic immune
                thrombocytopenia is hard, but managing chronic ITP with a TPO-RA
                medication doesn&lsquo;t have to be.
              </p>
            </>
          }
          maxWidth="500"
          bgImage={["managing-itp-hero.jpg", "managing-itp-hero.jpg"]}
          bgImageMobile={[
            "managing-itp-hero-mobile.jpg",
            "managing-itp-hero-mobile.jpg",
          ]}
          imageAlt="Doptelet platelet characters on amusement park swing ride"
        />

        <div className="pt-5">
          <Container>
            <Row className="align-items-center">
              <Col lg={{ span: 12 }}>
                <h2>Treatment goal discussion</h2>
                <p>
                  Having an open and honest conversation with your doctor can
                  help lead to care that best fits all your needs.
                </p>
                <p>
                  Have other treatments not worked well enough for you? Is
                  chronic ITP impacting your day-to-day life? Here are a few
                  questions to ask yourself before starting the conversation
                  with your doctor:
                </p>
                <img
                  src="/images/health-goals.jpg"
                  alt="Health Goals"
                  className="mb-3 mt-3 mt-lg-5 mb-lg-3 d-block ml-auto mr-auto ml-lg-0 mr-lg-0"
                />
                <p>
                  Everyone has their own likes and dislikes, which can affect
                  the treatment you and your doctor choose together.
                </p>
                <ul>
                  <li>What is your most important treatment goal?</li>
                  <li>Why does that goal matter to you?</li>
                  <li>How do you feel about your current treatment?</li>
                  <li>
                    Is your current treatment helping you reach your goal?
                  </li>
                  <li>
                    Does your current treatment affect your day-to-day life?
                  </li>
                  <li>
                    Will this treatment affect any vaccines you may need to get?
                  </li>
                  <li>What side effects might these treatments have?</li>
                  <li>
                    How long will it take for these treatments to start working?
                  </li>
                </ul>
                <img
                  src="/images/treatment-choices.jpg"
                  alt="Treatment Choices"
                  className="mb-3 mt-3 mt-lg-5 mb-lg-3 d-block ml-auto mr-auto ml-lg-0 mr-lg-0"
                />
                <p>
                  Certain chronic ITP treatments require injections for dosing
                  or food-type restrictions [like restrictions on dairy or iron
                  supplements] that may disrupt your routine.
                </p>
                <ul>
                  <li>Are you worried about your platelet counts?</li>
                  <li>
                    Would you rather take a pill or get an injection for dosing?
                  </li>
                  <li>
                    Would you be willing to plan your meals around your
                    treatment?
                  </li>
                  <li>
                    Will these treatments interact with any other medicines that
                    you take or foods that you eat?
                  </li>
                </ul>
                <img
                  src="/images/other-goals.jpg"
                  alt="Other Goals"
                  className="mb-3 mt-3 mt-lg-5 mb-lg-3 d-block ml-auto mr-auto ml-lg-0 mr-lg-0"
                />
                <p>
                  It&lsquo;s important to consider all of your options when
                  choosing a treatment, including how it fits into your work
                  schedule, ease of travel, and if you can afford it.
                </p>
                <ul>
                  <li>
                    Have you missed or had to skip any treatment doses in the
                    last few months? If so, why?
                  </li>
                  <li>
                    Do you regularly miss work to go to your doctor&lsquo;s
                    appointments?
                  </li>
                  <li>
                    Are you able to get to your doctor&lsquo;s appointments
                    easily? (think: car, bus, train, etc)
                  </li>
                  <li>
                    When, where, and for how long will you need to take these
                    treatments?
                  </li>
                  <li>Are you having trouble affording your treatments?</li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="mt-5 pt-2 cloud-blue-bg dopteller-diaries video-section">
          <Container>
            <Row>
              <Col lg={6}>
                <h2>
                  Tammy’s Tips: Important Questions to
                  <br className="d-none d-xl-block"></br> Ask Your Doctor About
                  Setting Treatment Goals
                </h2>
                <div className="video-wrapper d-lg-none">
                  <YoutubeEmbed
                    embedId="BuIhQABk2-A"
                    image={[
                      "tammystips-important-questions-to-ask-your-doctor.jpg",
                      "tammystips-important-questions-to-ask-your-doctor.jpg",
                    ]}
                    imageMobile={[
                      "tammystips-important-questions-to-ask-your-doctor.jpg",
                      "tammystips-important-questions-to-ask-your-doctor.jpg",
                    ]}
                    alt="Play Video: Important Questions to Ask Your Doctor About ITP Treatment"
                  />
                </div>
                <p className="pr-xl-5">
                  Tammy, a real patient on Doptelet, has asked her doctor many
                  questions over the years and wants to share helpful questions
                  to ask your doctor about setting treatment goals.
                </p>
                <div className="d-flex">
                  <PrimaryButton
                    title="MORE DOPTELET VIDEOS"
                    href="/support-and-resources/chronic-itp-videos/"
                    addClass="btn-transparent btn-arrow"
                  />
                </div>
              </Col>
              <Col lg={6} className="d-none d-lg-block">
                <div className="video-wrapper">
                  <YoutubeEmbed
                    embedId="BuIhQABk2-A"
                    image={[
                      "tammystips-important-questions-to-ask-your-doctor.jpg",
                      "tammystips-important-questions-to-ask-your-doctor.jpg",
                    ]}
                    imageMobile={[
                      "tammystips-important-questions-to-ask-your-doctor.jpg",
                      "tammystips-important-questions-to-ask-your-doctor.jpg",
                    ]}
                    alt="Play Video: Important Questions to Ask Your Doctor About ITP Treatment"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="pt-5">
          <Container>
            <Row className="align-items-center">
              <Col lg={{ span: 12 }}>
                <h2 className="mb-lg-5 mt-1">TREATMENT TAKES TEAMWORK</h2>
                <div className="d-lg-flex align-items-start blue-bg py-5 px-5">
                  <img
                    src="/images/icon_doctor_discussion_guide.svg"
                    alt="Icon for doctor discussion guide."
                    className="ml-auto mr-auto d-block mb-3 mb-lg-0 mr-lg-4"
                  />
                  <div className="copy">
                    <h2>Doctor Discussion Guide</h2>
                    <p>
                      Working with your doctor can help raise your chances of
                      finding a treatment that fits your needs and goals. Be
                      sure to take advantage of this guide that may support you
                      in having those important conversations with your doctor.
                    </p>
                    <div className="mt-5 d-lg-flex">
                      <PrimaryButton
                        title="DOWNLOAD GUIDE"
                        href="https://doptelet.com/pdf/Dr-Discussion-Guide.pdf"
                        addClass="download mr-lg-4 mb-3 mb-lg-0"
                        target="_blank"
                      />
                      <PrimaryButton
                        title="MORE RESOURCES"
                        href="/support-and-resources/itp-patient-resources/"
                        addClass="btn-sm btn-transparent btn-arrow"
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="mt-5 bottom-page-ctas">
          <Container fluid>
            <Row>
              <Col md={6} className="d-flex flex-column">
                <div className="content">
                  <img
                    src="/images/Platey_Ryder_Bubbles.png"
                    alt="Doptelet Platelet Character Blowing Bubbles"
                  />
                  <h2>Do you want updates on tips for managing chronic ITP?</h2>
                  <p className="flex-grow-1">
                    Sign up to access tips and tools for managing
                    ITP&mdash;plus, a surprise shipped to your doorstep!
                  </p>
                  <PrimaryButton
                    title="SIGN ME UP"
                    href="/support-and-resources/living-with-itp/"
                    addClass="btn-transparent btn-arrow align-self-start"
                  />
                </div>
              </Col>
              <Col md={6} className="cloud-blue-bg d-flex flex-column">
                <div className="content">
                  <img
                    src="/images/platey_Sage_Reading.png"
                    alt="Doptelet platelet character reading a book"
                  />
                  <h2>
                    Don&lsquo;t forget about financial assistance and resources
                  </h2>
                  <p className="flex-grow-1">
                    Let us help connect you to our financial assistance options
                    and resources for adults with chronic ITP.
                  </p>
                  <PrimaryButton
                    title="ITP SUPPORT &amp; RESOURCES"
                    href="/support-and-resources/"
                    addClass="btn-arrow align-self-start"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <ISIITPinline />
      </div>
      <FooterITP />
      <ISIwrapper>
        <ISIITP />
      </ISIwrapper>
      <BackToTopBtn />
    </div>
  );
}

export default ManagingITP;
